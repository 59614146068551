import React, { Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Header, Footer } from "components";

import PublicLayout from "./public";
import AuthLayout from "./auth";
import MainLayout from "./main";

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
};

const mapStateToProps = ({ auth, settings }: any) => ({
  auth: auth,
  title: settings.logo,
});
let previousPath = "";

const Layout = ({
  auth,
  children,
  title,
  location: { pathname, search },
}: any) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search;
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0);
  }
  setTimeout(() => {
    previousPath = currentPath;
  }, 300);

  // Layout Rendering
  const getLayout = () => {
    if (pathname === "/") {
      return "public";
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return "auth";
    }
    return "main";
  };

  const Container = Layouts[getLayout()];
  // const isUserAuthorized = auth.authorized;
  // const isUserLoading = auth.loading;
  // const isAuthLayout = getLayout() === "auth";

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    //if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
    //  return null;
    //}
    // redirect to login page if current is not login page and user not authorized
    //if (!isAuthLayout && !isUserAuthorized) {
    //  return <Redirect to="/auth/login" />;
    //}
    // in other case render previously set layout
    return <Container>{children}</Container>;
  };

  return (
    <Fragment>
      <Helmet titleTemplate={`${title} | %s`} title={title} />
      <div id="wrapper">
        <Header />
        {BootstrappedLayout()}
        <Footer />
      </div>
    </Fragment>
  );
};

export default withRouter(connect(mapStateToProps)(Layout));
