const actions = {
  SET_STATE: "crud/SET_STATE",
  RESET: "crud/RESET",
  SELECT: "crud/SELECT",

  LIST: "crud/LIST",
  LIST_SUCCESS: "crud/LIST_SUCCESS",
  LIST_FAIL: "crud/LIST_FAIL",

  GET: "crud/GET",
  GET_SUCCESS: "crud/GET_SUCCESS",
  GET_FAIL: "crud/GET_FAIL",

  REGISTER_LEAGUE: "crud/REGISTER_LEAGUE",

  CREATE_SUCCESS: "crud/CREATE_SUCCESS",
  CREATE_FAIL: "crud/CREATE_FAIL",
};

export default actions;
