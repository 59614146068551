import { API, graphqlOperation } from "aws-amplify";

import { capitalize } from "utils";

const { GraphQLClient } = require("graphql-request");

const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;
const graphQLClient = new GraphQLClient(graphqlEndpoint, {
  headers: {
    "x-api-key": apiKey,
  },
});

const graphqlList = (modelName, fields) => {
  const fieldNames = fields.map(({ name, views }) => {
    if (views && !views.includes(1)) {
      return "";
    }
    return name;
  });
  const list = `
    query ${modelName}($limit: Int) {
      ${modelName}(limit: $limit) {
        nextToken
        items {
          id ${fieldNames.join(" ")}
        }
      }
    }
  `;
  return list;
};

export const list = async ({ name, fields }) => {
  //   return new Promise((resolve, reject) => {
  const queryName = `list${capitalize(name)}s`;
  const graphqlQuery = graphqlList(queryName, fields);

  const data = await graphQLClient.request(graphqlQuery, { limit: 200 });
  const { items } = data[queryName];
  return items;

  //   .then(({ data }) => {
  //     const { items } = data[queryName]
  //     resolve(items)
  //   })
  //   .catch(error => {
  //     reject(error)
  //   })
  //   })
};

const graphqlCreate = (modelName, fields) => {
  const fieldNames = fields
    .filter((f) => f.type !== "relational-tournament")
    .map(({ name, type, relationalLabel = "name" }) => {
      if (type === "relational") {
        return `${name} { id ${relationalLabel} }`;
      }
      return name;
    });

  const create = `
    mutation ${modelName} ($input: ${capitalize(modelName)}Input!) {
      ${modelName}(input: $input) {
        ${fieldNames.join(" ")}
      }
    }
  `;
  return create;
};

export const create = async ({ name, fields, payload }) => {
  const queryName = `create${capitalize(name)}`;
  const graphqlQuery = graphqlCreate(queryName, fields);

  const data = await graphQLClient.request(graphqlQuery, { input: payload });
  const { item } = data[queryName];
  return item;
};

export const listVideos = async ({ name, fields }) => {
  return new Promise((resolve, reject) => {
    const queryName = `list${capitalize(name)}s`;
    const graphqlQuery = graphqlList(queryName, fields);

    API.graphql(graphqlOperation(graphqlQuery, { limit: 200 }))
      .then(({ data }) => {
        const { items } = data[queryName];
        resolve(
          items
            .filter((i) => i.showAsPresentation)
            .sort((a, b) => {
              if (a.date > b.date) {
                return -1;
              }
              return 1;
            })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });

  // const data = await graphQLClient.request(graphqlQuery, { limit: 200 });
  // const { items } = data[queryName];
  // return items
  //   .filter((i) => i.showAsPresentation)
  //   .sort((a, b) => {
  //     if (a.date > b.date) {
  //       return -1;
  //     }
  //     return 1;
  //   });
};

const graphqlGet = (modelName, fields) => {
  const fieldNames = fields.map(({ name, type, model }) => {
    if (type === "relational" || type === "relational-tournament") {
      if (modelName === "getParentLeague" && name === "tournaments") {
        return `${name} { items { id name category order }}`;
      }
      return `${model} { id name }`;
    }
    return name;
  });
  const get = `
    query ${modelName}($id: ID!) {
      ${modelName}(id: $id) {
        id ${fieldNames.join(" ")}
      }
    }
  `;
  return get;
};

export const get = async ({ name, fields, id }) => {
  //   return new Promise((resolve, reject) => {
  const queryName = `get${capitalize(name)}`;
  const graphqlQuery = graphqlGet(queryName, fields);

  const data = await graphQLClient.request(graphqlQuery, { id });
  const item = data[queryName];
  return item;

  //   .then(({ data }) => {
  //     const item = data[queryName]
  //     resolve(item)
  //   })
  //   .catch(error => {
  //     // Sentry.captureException(JSON.stringify(error))

  //     reject(error)
  //   })
  //   })
};

const parentLeaguesList = (modelName) => {
  const myGet = `
    query ${modelName}($limit: Int) {
      ${modelName}(limit: $limit) {
        nextToken
        items {
          id
          name
          showAsPresentation
          shortName
          timestamp
          status
          managers
          countryId
          date
          tournaments {
            items {
              id
              name
              category
              thumbnail
              date
              status
            }
          }
          country {
            id
            name
            code
            thumbnail
          }
          thumbnail
        }
      }
    }
  `;
  return myGet;
};

export const parentLeague = async ({ name }) => {
  const queryName = `list${capitalize(name)}s`;
  const graphqlQuery = parentLeaguesList(queryName);

  const data = await graphQLClient.request(graphqlQuery, { limit: 200 });
  let { items } = data[queryName];
  items = items.filter((pl) => pl.showAsPresentation === "true");
  return items;
};

const GetLeadersCache = /* GraphQL */ `
  query GetLeadersCache($leagueId: ID!, $category: String!) {
    getLeadersCache(leagueId: $leagueId, category: $category) {
      leagueId
      category
      data
    }
  }
`;

export const leadersStatistics = async ({ id: leagueId, name: category }) => {
  const { getLeadersCache } = await graphQLClient.request(GetLeadersCache, {
    leagueId,
    category,
  });

  const result = {
    leagueId,
    category,
    leadersStatistics: [],
  };

  if (getLeadersCache !== null) {
    result.leadersStatistics = JSON.parse(getLeadersCache.data);
  }
  return result.leadersStatistics;
};

const graphqlCreateRegisterLeague = () => {
  const graphqlQuery = `
  mutation createRequestLeague ($input: CreateRequestLeagueInput!) {
    createRequestLeague(input: $input){
      id name category quantity type
    }
  }
`;
  return graphqlQuery;
};

export const registerLeague = async ({ payload }) => {
  return new Promise((resolve, reject) => {
    const graphqlQuery = graphqlCreateRegisterLeague();
    API.graphql(graphqlOperation(graphqlQuery, { input: payload }))
      .then(({ data }) => {
        const item = data.createRequestLeague;
        resolve({ item });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPositionTable = async ({ id }) => {
  const graphqlQuery = `
  query getCache($id: String!) {
    getCache(partitionKey: "PositionTable", rowKey: $id) {
      data
    }
  }
`;

  const data = await graphQLClient.request(graphqlQuery, { id });
  const item = data.getCache;
  let items = [];
  if (item) {
    const tmpItem = JSON.parse(item.data);
    items = tmpItem;
  }
  return items;
};
