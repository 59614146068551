import { API, graphqlOperation } from "aws-amplify";
import store from "store";

// import { notification } from 'antd'
import wretch from "wretch";

const API_URL = process.env.REACT_APP_API_URL;
const serverApi = wretch(API_URL);

export async function avg300Login(data) {
  return new Promise((resolve) => {
    serverApi.defaults({ headers: { Authorization: null } }, true);
    serverApi
      .url("auth/login/")
      .post(data)
      .json((response) => {
        serverApi.defaults(
          { headers: { Authorization: `Token ${response.token}` } },
          true
        );
        store.set("avg300.token", response.token);
        resolve(response);
      })
      .catch((response) => {
        // notification.warning({
        //   message: 'Error',
        //   description: response.message,
        // })
        resolve(false);
      });
  });
}

export async function avg300CurrentAccount() {
  const token = store.get("avg300.token");
  return new Promise((resolve) => {
    serverApi.defaults({ headers: { Authorization: `Token ${token}` } }, true);
    serverApi
      .url("current-user")
      .get()
      .json((response) => {
        resolve(response);
      })
      .catch(() => {
        resolve(false);
      });
  });
}

export async function avg300Logout() {
  store.remove("avg300.token");
}

export async function avg300Register(data) {
  return new Promise((resolve) => {
    serverApi.defaults({ headers: { Authorization: null } }, true);
    serverApi
      .url("auth/signup/")
      .post(data)
      .json((response) => {
        resolve(response);
      })
      .catch((response) => {
        // notification.warning({
        //   message: 'Error',
        //   description: response.message,
        // })
        resolve(false);
      });
  });
}

export async function avg300ConfirmEmail(data) {
  return new Promise((resolve) => {
    serverApi.defaults({ headers: { Authorization: null } }, true);
    serverApi
      .url("auth/signup/verify-email/")
      .post(data)
      .json((response) => {
        resolve(response);
      })
      .catch((response) => {
        // notification.warning({
        //   message: 'Error',
        //   description: response.message,
        // })
        resolve(false);
      });
  });
}

export async function avg300ForgotPassword(data) {
  return new Promise((resolve) => {
    serverApi.defaults({ headers: { Authorization: null } }, true);
    serverApi
      .url("auth/password/reset/")
      .post(data)
      .json((response) => {
        resolve(response);
      })
      .catch((response) => {
        // notification.warning({
        //   message: 'Error',
        //   description: response.message,
        // })
        resolve(false);
      });
  });
}

export async function avg300RecoverConfirm(data) {
  return new Promise((resolve) => {
    serverApi.defaults({ headers: { Authorization: null } }, true);
    serverApi
      .url("auth/password/reset/confirm/")
      .post(data)
      .json((response) => {
        resolve(response);
      })
      .catch((response) => {
        // notification.warning({
        //   message: 'Error',
        //   description: response.message,
        // })
        resolve(false);
      });
  });
}

const graphqlCreateContactMessage = () => {
  const graphqlQuery = `
  mutation createContactMessage ($input: CreateContactMessageInput!) {
    createContactMessage(input: $input){
      id name email message phone
    }
  }
`;
  return graphqlQuery;
};

export const contactUs = async ({ payload }) => {
  return new Promise((resolve, reject) => {
    const graphqlQuery = graphqlCreateContactMessage();
    API.graphql(graphqlOperation(graphqlQuery, { input: payload }))
      .then(({ data }) => {
        const item = data.createContactMessage;
        resolve({ item });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
