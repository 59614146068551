/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import BlockSmarty from "assets/images/block/baseball-everywhere.png";
import Avg300 from "assets/images/block/avg300.png";
import Avg300_score from "assets/images/block/avg300_score.png";
import { FormattedMessage } from "react-intl";

export default () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div
            className="col-12 col-md-7 col-lg-6"
            data-aos="fade-up"
            data-aos-delay="0"
          >
            <span className="badge badge-pill badge-success badge-soft font-weight-light pl-2 pr-2 pt--6 pb--6 mb-2">
              <FormattedMessage id="block.notification" />
            </span>

            <h2 className="mb-4 font-weight-normal">
              <FormattedMessage id="block.questions" />
              {/* What would you do if <span className="text-success">time</span> really matter? */}
            </h2>

            <p className="fs--18 mb-4">
              <FormattedMessage id="block.answer" />
            </p>

            <div className="d-flex mb-4">
              {/* icon */}
              <div className="text-primary">
                <img width="40" height="40" src={Avg300} alt="..." />
              </div>

              <div className="ml-4 mr-4">
                {/* heading */}
                <h3 className="h5 mb-1">AVERAGE300</h3>

                {/* text */}
                <p>
                  <FormattedMessage id="block.avg300" />
                </p>
              </div>
            </div>

            <div className="d-flex mb-4">
              {/* icon */}
              <div className="text-primary">
                <img width="40" height="40" src={Avg300_score} alt="..." />
              </div>

              <div className="ml-4 mr-4">
                {/* heading */}
                <h3 className="h5 mb-1">AVERAGE300 SCORE</h3>

                {/* text */}
                <p>
                  <FormattedMessage id="block.avg300score" />
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-md-5 col-lg-6"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="d-table">
              <div className="d-table-cell align-middle">
                <div className="transform-3d-right">
                  <img
                    className="shadow-xlg img-fluid rounded-xl z-index-1"
                    src={BlockSmarty}
                    width="570"
                    height="357"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
