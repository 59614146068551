/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
// import CoverImage from "assets/images/cover/startup.png";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default () => {
  return (
    <section className="p-0 bg-cover">
      <div className="container d-middle min-h-75vh pt-5">
        <div className="row w-100 text-center-xs">
          <div
            className="d-middle col-12 col-md-6 order-2 order-md-1 pb-5"
            data-aos="fade-up"
            data-aos-delay="0"
          >
            <div className="mb-5">
              <h1 className="font-weight-light mb-4">
                <FormattedMessage id="cover.welcometo" />{" "}
                <span className="text-primary font-weight-medium">
                  AVERAGE300
                </span>
                ,{" "}
                <span className="font-weight-medium">
                  <FormattedMessage id="cover.baseballplatform" />
                </span>
                !
              </h1>

              <p className="lead">
                <FormattedMessage id="cover.avgdescription" />
                <br />{" "}
              </p>
              <p className="lead">
                <span className="font-weight-medium">
                  <FormattedMessage id="cover.question" />
                </span>
              </p>
              <div className="mt-4">
                <Link
                  to="/league/league-request"
                  className="btn btn-primary transition-hover-top mb-3 d-block-xs"
                >
                  <i className="fi fi-arrow-right"></i>
                  <FormattedMessage id="cover.register" />
                </Link>

                <Link
                  to="/auth/login"
                  className="btn btn-primary transition-hover-top btn-soft mb-3 d-block-xs"
                >
                  <FormattedMessage id="cover.enter" />
                </Link>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-md-6 order-1 order-md-2 pb-5 text-align-end text-center-xs"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {/* svg image */}
            {/* <img
              width="600"
              height="400"
              className="img-fluid"
              src={CoverImage}
              alt="..."
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};
