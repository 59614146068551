import actions from "./actions";

const initialState = {
  id: "",
  name: "",
  family_name: "",
  avatar: "",
  role: "",
  email: "",
  phone_number: "",
  authorized: false,
  loading: false,
  groups: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
