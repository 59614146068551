/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { FormattedMessage } from "react-intl";

// Everywhere! Always! Connected!
export default () => {
  return (
    <section className="py-5">
      <div className="container">
        <div className="row text-center-xs">
          <div className="col-12 col-md-6">
            <h3 className="m-0">
              <FormattedMessage id="infobox.download.on.basaball" />
            </h3>
            <p className="m-0">
              <FormattedMessage id="infobox.download.on.sentence" />
            </p>
          </div>

          <div className="col-12 mb-5 d-block d-md-none"></div>

          <div className="col-12 col-md-6 text-align-end text-center-xs">
            {/* App Store */}
            <a
              href="https://apps.apple.com/co/developer/average-300-media-group-llc/id1057765214"
              target="blank"
              className="btn btn-dark bg-gradient-dark b-0 mx-1 rounded-xl transition-hover-top"
            >
              <i className="float-start">
                <svg height="32" viewBox="0 0 90 90">
                  <path
                    fill="#ffffff"
                    d="M49.645 6.817c3.232-3.8 8.695-6.627 13.203-6.817.575 5.279-1.541 10.565-4.669 14.374-3.133 3.802-8.261 6.764-13.294 6.369-.684-5.165 1.851-10.551 4.76-13.926zm25.646 72.01c-3.741 5.485-7.61 10.938-13.718 11.053-6.012.114-7.938-3.571-14.799-3.571-6.866 0-9.016 3.457-14.69 3.686-5.897.222-10.382-5.926-14.152-11.387-7.703-11.168-13.585-31.576-5.677-45.35 3.916-6.84 10.934-11.167 18.537-11.282 5.792-.107 11.263 3.917 14.799 3.917 3.544 0 10.185-4.831 17.165-4.122 2.922.121 11.123 1.181 16.391 8.912-.417.274-9.789 5.745-9.676 17.141.114 13.614 11.891 18.137 12.029 18.199-.108.32-1.884 6.459-6.209 12.804z"
                  />
                </svg>
              </i>

              <span className="float-start text-align-start px-2">
                <span className="d-block line-height-1 fs--13">
                  <FormattedMessage id="download.on.the" />
                </span>
                <span className="d-block line-height-1 fs--20 pt-1">
                  App Store
                </span>
              </span>
            </a>

            <div className="mt-3 d-block d-md-none"></div>

            {/* Play Store */}
            <a
              href="https://play.google.com/store/apps/developer?id=AVERAGE+300+MEDIA+GROUP+LLC&hl=en&gl=US"
              target="blank"
              className="btn btn-dark bg-gradient-dark b-0 mx-1 rounded-xl transition-hover-top"
            >
              <i className="float-start">
                <svg height="32" viewBox="0 0 512 512">
                  <path
                    fill="#ffffff"
                    d="M99.617 8.057c-11.664-7.537-25.674-9.815-38.815-6.713l230.932 230.933 74.846-74.846-266.963-149.374zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877-235.877-235.879zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711l-230.918 230.918c3.786.891 7.639 1.371 11.492 1.371 9.522 0 19.011-2.708 27.31-8.07l266.965-149.372-74.849-74.847z"
                  />
                </svg>
              </i>

              <span className="float-start text-align-start px-2">
                <span className="d-block line-height-1 fs--13">
                  <FormattedMessage id="download.on.the" />
                </span>
                <span className="d-block line-height-1 fs--20 pt-1">
                  Play Store
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
