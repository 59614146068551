/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { Path, useForm, UseFormRegister, SubmitHandler } from "react-hook-form";
import { UpdateUserProps } from "interfaces";

const style = {
  // backgroundImage: 'url (' + michael + ')',
};

type InputProps = {
  name: Path<UpdateUserProps>;
  register: UseFormRegister<UpdateUserProps>;
  required?: boolean;
  type?: string;
  placeholder: string;
  disabled?: boolean;
};

type Options = {
  value: string;
  key: string;
};

type SelectProps = {
  name: Path<UpdateUserProps>;
  register: UseFormRegister<UpdateUserProps>;
  required?: boolean;
  options: Options[];
};

// The following component is an example of your existing Input Component
const Input = ({
  name,
  register,
  required,
  type = "text",
  placeholder,
  disabled,
}: InputProps) => (
  <input
    {...register(name, { required })}
    {...{ type, readOnly: disabled, className: "form-control", placeholder }}
  />
);

const Select = ({ name, register, required, options }: SelectProps) => {
  const intl = useIntl();

  return (
    <select
      {...register(name, { required })}
      {...{ id: name, className: "form-control bs-select" }}
    >
      {options.map((o) => {
        return (
          <option value={o.key}>{intl.formatMessage({ id: o.value })}</option>
        );
      })}
    </select>
  );
};

const mapStateToProps = ({ auth }: any) => ({
  auth,
  loading: auth.loading,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(({ dispatch, auth }: PropsFromRedux) => {
  const { register, handleSubmit } = useForm<UpdateUserProps>({
    defaultValues: auth,
  });
  const intl = useIntl();

  const onSubmit: SubmitHandler<UpdateUserProps> = (data) => {
    dispatch({
      type: "auth/UPDATE_PROFILE",
      payload: data,
    });
  };

  return (
    <form className="bs-validate" onSubmit={handleSubmit(onSubmit)}>
      <div className="p-4 shadow-xs border bt-0 mb-4 bg-white">
        <div className="row">
          <div className="col-12 col-md-3 text-center">
            <label className="w--120 h--120 rounded-circle text-center position-relative d-inline-block cursor-pointer border border-secondary border-dashed bg-white">
              <a
                href="#?action=remove_avatar"
                className="js-file-upload-avatar-ajax-circle-remove-preadded position-absolute bottom-0 mb--n15 w-100 z-index-3"
              >
                <span
                  className="d-inline-block btn btn-sm btn-pill bg-secondary text-white pt--4 pb--4 pl--10 pr--10"
                  data-tooltip="tooltip"
                  data-original-title="remove avatar"
                >
                  <i className="fi fi-close m-0"></i>
                </span>
              </a>

              <span className="z-index-2 js-file-input-avatar-ajax-circle-container-preadded d-block absolute-full z-index-1">
                <span
                  style={style}
                  className="js-file-input-item d-inline-block position-relative overflow-hidden text-center rounded-circle m-0 p-0 animate-bouncein bg-cover w-100 h-100"
                ></span>
              </span>

              <input
                name="account_avatar"
                type="file"
                data-file-ext="jpg, png"
                data-file-max-size-kb-per-file="5000"
                data-file-ext-err-msg="Allowed:"
                data-file-size-err-item-msg="File too large!"
                data-file-size-err-total-msg="Total allowed size exceeded!"
                data-file-toast-position="bottom-center"
                data-file-preview-container=".js-file-input-avatar-ajax-circle-container-preadded"
                data-file-preview-show-info="false"
                data-file-preview-className="rounded-circle m-0 p-0 animate-bouncein"
                data-file-preview-img-height="118"
                data-file-btn-clear="a.js-file-upload-avatar-ajax-circle-remove-preadded"
                data-file-preview-img-cover="true"
                className="custom-file-input absolute-full"
              />

              <svg
                className="fill-gray-600 rounded-circle z-index-0 m-4"
                viewBox="0 0 60 60"
              >
                <path d="M41.014,45.389l-9.553-4.776C30.56,40.162,30,39.256,30,38.248v-3.381c0.229-0.28,0.47-0.599,0.719-0.951c1.239-1.75,2.232-3.698,2.954-5.799C35.084,27.47,36,26.075,36,24.5v-4c0-0.963-0.36-1.896-1-2.625v-5.319c0.056-0.55,0.276-3.824-2.092-6.525C30.854,3.688,27.521,2.5,23,2.5s-7.854,1.188-9.908,3.53c-2.368,2.701-2.148,5.976-2.092,6.525v5.319c-0.64,0.729-1,1.662-1,2.625v4c0,1.217,0.553,2.352,1.497,3.109c0.916,3.627,2.833,6.36,3.503,7.237v3.309c0,0.968-0.528,1.856-1.377,2.32l-8.921,4.866C1.801,46.924,0,49.958,0,53.262V57.5h46v-4.043C46,50.018,44.089,46.927,41.014,45.389z" />
                <path d="M55.467,46.526l-9.723-4.21c-0.23-0.115-0.485-0.396-0.704-0.771l6.525-0.005c0,0,0.377,0.037,0.962,0.037c1.073,0,2.638-0.122,4-0.707c0.817-0.352,1.425-1.047,1.669-1.907c0.246-0.868,0.09-1.787-0.426-2.523c-1.865-2.654-6.218-9.589-6.354-16.623c-0.003-0.121-0.397-12.083-12.21-12.18c-1.187,0.01-2.309,0.156-3.372,0.413c0.792,2.094,0.719,3.968,0.665,4.576v4.733c0.648,0.922,1,2.017,1,3.141v4c0,1.907-1.004,3.672-2.607,4.662c-0.748,2.022-1.738,3.911-2.949,5.621c-0.15,0.213-0.298,0.414-0.443,0.604v2.86c0,0.442,0.236,0.825,0.631,1.022l9.553,4.776c3.587,1.794,5.815,5.399,5.815,9.41V57.5H60v-3.697C60,50.711,58.282,47.933,55.467,46.526z" />
              </svg>
            </label>
          </div>

          <div className="col">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6">
                <div className="form-label-group mb-3">
                  <Input
                    name="name"
                    placeholder={intl.formatMessage({ id: "first.name" })}
                    register={register}
                    required
                  />
                  <label htmlFor="name">
                    {intl.formatMessage({ id: "first.name" })}
                  </label>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-6">
                <div className="form-label-group mb-3">
                  <Input
                    name="family_name"
                    placeholder={intl.formatMessage({ id: "last.name" })}
                    register={register}
                    required
                  />
                  <label htmlFor="family_name">
                    {intl.formatMessage({ id: "last.name" })}
                  </label>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-6">
                <div className="input-group-over">
                  <div className="form-label-group mb-3">
                    <Input
                      name="email"
                      placeholder={intl.formatMessage({ id: "email" })}
                      register={register}
                      type="email"
                      required
                      disabled
                    />
                    <label htmlFor="email">
                      {intl.formatMessage({ id: "email" })}
                    </label>
                  </div>

                  {/* <a
                    id="email_edit_show"
                    href="javascript:;"
                    className="btn fs--13"
                  >
                    <i className="fi fi-pencil m-0"></i>
                  </a> */}

                  {/* <a
                    id="email_edit_hide"
                    href="javascript:;"
                    className="btn fs--12 hide"
                  >
                    <i className="fi fi-close m-0"></i>
                  </a> */}
                </div>

                <div
                  id="email_edit_info"
                  className="mt-1 mb-4 fs--13 text-muted hide"
                >
                  A confirmation link will be sent to current email address
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-6">
                <div className="form-label-group mb-3">
                  <Input
                    name="phone_number"
                    placeholder={intl.formatMessage({ id: "phone" })}
                    register={register}
                    required
                  />
                  <label htmlFor="phone_number">
                    {intl.formatMessage({ id: "phone" })}
                  </label>
                </div>
              </div>
            </div>

            {/* <select
              required
              name="user_country"
              className="form-control bs-select"
              data-live-search="true"
            >
              <option value="">Language</option>
              <option value="1">[EN] English</option>
              <option value="2">[SP] Spanish</option>
            </select> */}
          </div>
        </div>

        {/* <div className="border-top pt-4 mt-4">
          <label className="form-checkbox form-checkbox-primary mb-0">
            <input type="checkbox" name="user_newsletter_accept" value="1" />
            <i></i> Keep me up to date on news and exclusive offers
          </label>
        </div> */}
      </div>

      <div className="p-4 shadow-xs rounded mb-4 bg-white">
        <a
          href="#user_edit_password"
          className="btn-toggle btn fs--14 btn-light collapsed"
          data-toggle="collapse"
          aria-expanded="false"
        >
          <span className="group-icon float-start">
            <i className="fi fi-arrow-down"></i>
            <i className="fi fi-close"></i>
          </span>{" "}
          &nbsp; CHANGE PASSWORD
        </a>

        <div id="user_edit_password" className="collapse">
          <div className="row mt-3">
            <div className="col-12 col-sm-6 col-md-6">
              <div className="input-group-over">
                <div className="form-label-group mb-3">
                  <input
                    placeholder="Current Password"
                    id="account_current_password"
                    name="account_current_password"
                    type="password"
                    className="form-control"
                  />
                  <label htmlFor="account_current_password">
                    Current Password
                  </label>
                </div>

                <a
                  href="#"
                  className="btn fs--12 btn-password-type-toggle"
                  data-target="#account_current_password"
                >
                  <span className="group-icon">
                    <i className="fi fi-eye m-0"></i>
                    <i className="fi fi-close m-0"></i>
                  </span>
                </a>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6">
              <div className="input-group-over">
                <div className="form-label-group mb-3">
                  <input
                    placeholder="New Password"
                    id="account_new_password"
                    name="account_new_password"
                    type="password"
                    className="form-control"
                  />
                  <label htmlFor="account_new_password">New Password</label>
                </div>

                <a
                  href="#"
                  className="btn fs--12 btn-password-type-toggle"
                  data-target="#account_new_password"
                >
                  <span className="group-icon">
                    <i className="fi fi-eye m-0"></i>
                    <i className="fi fi-close m-0"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="clearfix mt--60">
        <div className="text-align-end">
          <a
            href="#account_deactivate_confirm"
            className="text-gray-500 text-decoration-none font-light collapsed"
            data-toggle="collapse"
            aria-expanded="false"
          >
            <span className="group-icon fs--13">
              <i className="fi fi-arrow-down"></i>
              <i className="fi fi-close"></i>
            </span>

            <span className="pl-2 pr-2">delete my account</span>
          </a>
        </div>

        <div id="account_deactivate_confirm" className="collapse">
          <div className="alert b-0 bg-danger-soft mt-3 mb-5 p-4 clearfix">
            <p>
              NOTE: There is no way to restore a deleted account! Your account
              will be permanently deleted from our system. Please, make sure
              that this is what you want.
            </p>

            <p className="m-0 fs--13">
              For security reasons, your account will be deactivated and
              permanently deleted within 30 days. Deletion progress will be
              canceled if you sign back to your account.
            </p>

            <i className="arrow arrow-lg arrow-bottom arrow-center border-danger-soft"></i>
          </div>

          <div className="row mb--60">
            <div className="col-12 offset-lg-2 col-lg-7">
              <div className="input-group-over">
                <div className="form-label-group mb-1">
                  <input
                    placeholder="Current Password"
                    id="account_del_current_password"
                    name="account_current_password"
                    type="password"
                    className="form-control"
                  />
                  <label htmlFor="account_del_current_password">
                    Current Password
                  </label>
                </div>

                <a
                  href="#"
                  className="btn fs--12 btn-password-type-toggle"
                  data-target="#account_del_current_password"
                >
                  <span className="group-icon">
                    <i className="fi fi-eye m-0"></i>
                    <i className="fi fi-close m-0"></i>
                  </span>
                </a>
              </div>

              <div className="mt--7 fs--13 text-muted mb--30">
                For security reasons, your current password is needed.
              </div>

              <div className="form-label-group mb-1">
                <textarea
                  rows={5}
                  placeholder="Feedback"
                  id="account_deactivate_feedback"
                  name="account_deactivate_feedback"
                  className="form-control"
                ></textarea>
                <label htmlFor="account_deactivate_feedback">Feedback</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-top pt-4 mt-1">
        <button type="submit" className="btn btn-primary">
          <i className="fi fi-check"></i>
          {intl.formatMessage({ id: "save.changes" })}
        </button>
      </div>
    </form>
  );
});
