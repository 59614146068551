import actions from "./actions";

const initialState = {
  items: [],
  item: null,
  itemsByIds: {},
  errors: null,
  loading: "idle",
  // "idle" | "pending" | "succeeded" | "failed";
  loadingDetail: "idle",
  created: false,
  fetched: false,
};

const crudReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    // case actions.GET:
    //   return { ...state, loading: true, error: null }
    case actions.GET_SUCCESS: {
      const item = action.payload;
      return { ...state, item, loadingDetail: "succeded", fetched: true };
    }
    case actions.LIST_SUCCESS: {
      const items = action.payload;
      const itemsByIds = {};
      items.forEach((e, index) => {
        const { id } = e;
        itemsByIds[id] = index;
      });
      return {
        ...state,
        itemsByIds,
        items: action.payload,
        loading: "succeeded",
        fetched: true,
      };
    }
    case actions.LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: "succeeded",
        fetched: true,
      };
    case actions.GET_FAIL:
      return { ...state, error: action.payload, loadingDetail: "succeded" };
    case actions.UPDATE_SUCCESS: {
      const { item } = action.payload;
      const { id } = item;
      const { itemsByIds } = state;
      const items = [...state.items];
      items[itemsByIds[id]] = item;
      return {
        ...state,
        items,
        ...item,
        loading: false,
        error: null,
        created: true,
      };
    }
    case actions.CREATE_SUCCESS: {
      const { item } = action.payload;
      const { itemsByIds } = state;
      const items = [...state.items];
      items.push(item);
      itemsByIds[item.id] = items.length;
      return {
        ...state,
        item,
        items,
        loading: "succeeded",
        error: null,
        created: true,
      };
    }
    case actions.CREATE_FAIL:
      return { ...state, errors: action.payload, loading: "failed" };
    default:
      return state;
  }
};

export default crudReducer;
