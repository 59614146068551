import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import actions from "redux/crud/actions";

const style = {
  width: 125,
  height: 125,
};

const model = {
  name: "league",
  fields: [
    { name: "id" },
    { name: "name" },
    { name: "thumbnail" },
    { name: "date" },
    { name: "status" },
  ],
};

const mapStateToProps = ({ league }: any) => ({
  item: league.item,
  loadingDetail: league.loadingDetail,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type InfoLeagueProps = PropsFromRedux & { id: string };

export default connector(
  ({ dispatch, item, loadingDetail, id }: InfoLeagueProps) => {
    const get = React.useCallback(() => {
      dispatch({
        model,
        type: actions.GET,
        id,
      });
    }, []);

    React.useEffect(() => {
      get();
    }, [id]);

    if (loadingDetail !== "succeded") {
      return <>...</>;
    }

    return (
      <div id="tab_account" className="tab-pane active">
        <form
          method="post"
          action="#"
          noValidate
          className="bs-validate"
          encType="multipart/form-data"
        >
          <div className="p-4 shadow-xs border bt-0 mb-4 bg-white">
            <div className="row">
              <div className="col-12 col-md-3 text-center">
                <label className="w--120 h--120 rounded-circle text-center position-relative d-inline-block cursor-pointer  bg-white">
                  <span className="z-index-2 js-file-input-avatar-ajax-circle-container-preadded d-block absolute-full z-index-1">
                    <span
                      style={style}
                      className="js-file-input-item d-inline-block position-relative overflow-hidden text-center rounded-circle m-0 p-0 animate-bouncein bg-cover w-100 h-100"
                    ></span>
                  </span>

                  <img src={item.thumbnail} alt="" style={style} />
                </label>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6">
                    <label htmlFor="account_first_name">{item.name}</label>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6">
                    <label htmlFor="account_last_name">{item.date}</label>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
);
