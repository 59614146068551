import React from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";

import english from "./locales/en-US";
import spanish from "./locales/es-ES";

const locales = {
  "en-US": {
    locale: "en-EN",
    messages: english,
  },
  "es-ES": {
    locale: "es-ES",
    messages: spanish,
  },
};

const mapStateToProps = ({ settings }) => ({ settings });

const Localization = ({ children, settings: { locale } }) => {
  const currentLocale = locales[locale];
  return (
    <IntlProvider
      locale={currentLocale.locale}
      messages={currentLocale.messages}
    >
      {children}
    </IntlProvider>
  );
};

export default connect(mapStateToProps)(Localization);
