import * as React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import Layout from "layouts";

const localRoutes = [
  {
    path: "/",
    Component: React.lazy(() => import("pages/home")),
    exact: true,
  },
  {
    path: "/auth/login",
    Component: React.lazy(() => import("pages/auth/login")),
    exact: true,
  },
  {
    path: "/auth/signup",
    Component: React.lazy(() => import("pages/auth/signup")),
    exact: true,
  },
  // {
  //   path: "/auth/confirm/signup",
  //   Component: React.lazy(() => import("pages/auth/activationcode")),
  //   exact: true,
  // },
  {
    path: "/auth/forgot-password",
    Component: React.lazy(() => import("pages/auth/passwordreset")),
    exact: true,
  },
  // For v1
  {
    path: "/auth/forgot-password-confirm/:uid/:token",
    Component: React.lazy(() => import("pages/auth/passwordconfirm")),
    exact: true,
  },
  // {
  //   path: "/auth/forgot-password-confirm",
  //   Component: React.lazy(() => import("pages/auth/passwordconfirm")),
  //   exact: true,
  // },
  {
    path: "/auth/confirm/signup/:key",
    Component: React.lazy(() => import("pages/auth/activationcode")),
    exact: true,
  },
];

const avg300Routes = [
  // {
  //   path: "/pricing/pricing-plan",
  //   Component: React.lazy(() => import("pages/pricing/pricingplan")),
  //   exact: true,
  // },
  {
    path: "/video/video-league",
    Component: React.lazy(() => import("pages/videosplan")),
    exact: true,
  },
  {
    path: "/documentation/documentacion-api",
    Component: React.lazy(() => import("pages/documentation")),
    exact: true,
  },
  {
    path: "/league/league-request",
    Component: React.lazy(() => import("pages/leaguerequest")),
    exact: true,
  },
  {
    path: "/profile/account-settings",
    Component: React.lazy(() => import("pages/profile")),
    exact: true,
  },
  {
    path: "/contact-us",
    Component: React.lazy(() => import("pages/avg300/contactus")),
    exact: true,
  },
  {
    path: "/leagues",
    Component: React.lazy(() => import("pages/leagues")),
    exact: true,
  },
  {
    path: "/leagues/:leagueID",
    Component: React.lazy(() => import("pages/leaguedetail")),
  },
];

const routes = [...localRoutes, ...avg300Routes];

const Router = ({ history }: any) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <Switch location={location}>
                {routes.map(({ path, Component, exact }) => (
                  <Route
                    path={path}
                    key={path}
                    exact={exact}
                    render={() => {
                      return (
                        <React.Suspense fallback={null}>
                          <Component />
                        </React.Suspense>
                      );
                    }}
                  />
                ))}
                <Redirect to="/auth/404" />
              </Switch>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default Router;
