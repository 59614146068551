/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import actions from "redux/crud/actions";
import { connect } from "react-redux";
import ModalVideo from "react-modal-video";

const mapStateToProps = ({ video }) => ({ video });
connect(mapStateToProps);

const connector = connect(mapStateToProps);

export default connector(({ dispatch, video }) => {
  const { open, url } = video;

  const close = () => {
    dispatch({
      model: "video",
      type: actions.SELECT,
      payload: { open: false, url: null },
    });
  };

  return (
    <ModalVideo
      channel="custom"
      autoplay
      isOpen={open}
      url={url}
      onClose={close}
    />
  );
});
