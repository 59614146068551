import { all } from "redux-saga/effects";
import auth from "./authv1/sagas";
// import menu from "./menu/sagas";
import settings from "./settings/sagas";
import crud from "./crud/sagas";

export default function* rootSaga() {
  yield all([
    auth(),
    // menu(),
    settings(),
    crud(),
  ]);
}
