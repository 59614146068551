import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import actions from "redux/crud/actions";
import { Table } from "./elements";

const fields = [
  { key: "name", title: "Nombre" },
  { key: "played", title: "JJ" },
  { key: "wins", title: "JG" },
  { key: "losses", title: "JP" },
  { key: "ties", title: "JE" },
  { key: "prom_win_loss", title: "PROM" },
  { key: "diff", title: "DIF" },
];

const mapStateToProps = ({ positionTable }: any) => ({
  items: positionTable.items,
  loading: positionTable.loading,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type PositionTableProps = PropsFromRedux & { id: string };

export default connector(({ dispatch, items, loading, id }: any) => {
  const get = React.useCallback(() => {
    dispatch({
      model: { name: "positionTable" },
      type: actions.LIST,
      method: "getPositionTable",
      payload: { id },
    });
  }, []);

  React.useEffect(() => {
    get();
  }, [id]);

  if (loading !== "succeeded") {
    return <>...</>;
  }

  if (items.length === 0) {
    return <FormattedMessage id="empty.position.table" />;
  }

  return <Table {...{ columns: fields, dataSource: items }} />;
});
