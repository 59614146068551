/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";

export default ({ columns, dataSource }: any) => {
  return (
    <div className="portlet-body">
      <div className="container py-6">
        {/* <h5 className="mb-4 mt-5 text-gray-400">Position Table</h5> */}
        <div id="clipboard_0" className="p--15 bg-white rounded">
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  {columns.map((e: any) => (
                    <th key={e.key} scope="col">
                      {e.title}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {dataSource.map((el: any) => (
                  <tr key={el.id}>
                    {columns.map((c: any) => (
                      <td key={c.key}>
                        {c.render ? c.render(el[c.key], el) : el[c.key] ?? 0}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
