/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ProfileForm } from "./index";

const connector = connect();
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(({ dispatch }: PropsFromRedux) => {
  const logout = (e: any) => {
    e.preventDefault();
    dispatch({
      type: "auth/LOGOUT",
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-3 mb--60">
          <nav className="sticky-kit nav-deep nav-deep-light">
            <button
              className="clearfix btn btn-toggle btn-sm btn-block text-align-left shadow-md border rounded mb-1 d-block d-lg-none"
              data-target="#nav_responsive"
              data-toggle-container-className="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
            >
              <span className="group-icon px-2 py-2 float-start">
                <i className="fi fi-bars-2"></i>
                <i className="fi fi-close"></i>
              </span>

              <span className="h5 py-2 m-0 float-start">Account Menu</span>
            </button>

            <h5 className="pt-3 pb-3 m-0 d-none d-lg-block">Account Menu</h5>

            <ul
              id="nav_responsive"
              className="nav flex-column d-none d-lg-block"
            >
              <li className="nav-item active">
                <Link className="nav-link px-0" to="/profile/account-settings">
                  <i className="fi fi-arrow-end m-0 fs--12"></i>
                  <span className="px-2 d-inline-block">Account Settings</span>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  href="javascript:void"
                  onClick={logout}
                  className="prefix-icon-ignore dropdown-footer dropdown-custom-ignore"
                >
                  <i className="fi fi-power float-start"></i>
                  <FormattedMessage id="logout" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-9">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className="nav-link nav-link-remember active"
                data-toggle="tab"
                href="#tab_account"
              >
                Account
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div id="tab_account" className="tab-pane active">
              <ProfileForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
