/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonProps } from "interfaces";

export default ({
  label,
  loading,
  className = "btn btn-primary btn-soft btn-block",
}: ButtonProps) => {
  return (
    <button {...{ className }}>
      {loading && (
        <i
          className="fi fi-spin fi-circle-spin"
          role="status"
          aria-hidden="true"
        ></i>
      )}
      <FormattedMessage id={label} />
    </button>
  );
};
