import * as React from "react";
import { useIntl } from "react-intl";
import { LeaderDetail } from "components";

const categories = [
  {
    name: "pitchers",
    label: "pitchers",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "ERA", dataIndex: "pitEra" },
      { title: "G-P", dataIndex: "pitWinLoss" },
      { title: "IP", dataIndex: "pitIp" },
      { title: "K", dataIndex: "pitStrikeOuts" },
      { title: "WHIP", dataIndex: "pitWhip" },
    ],
  },
  {
    name: "batters",
    label: "batters",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "AVG", dataIndex: "batAvg" },
      { title: "AB", dataIndex: "batAb" },
      { title: "RBI", dataIndex: "batRbi" },
      { title: "HR", dataIndex: "batHr" },
      { title: "H", dataIndex: "batHits" },
      { title: "SLG", dataIndex: "batSlug" },
    ],
  },
  {
    name: "batters_homeruns",
    label: "batters.homeruns",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "HR", dataIndex: "batHr" },
    ],
  },
  {
    name: "batters_run_batted_in",
    label: "batters.run.batted.in",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "RBI", dataIndex: "batRbi" },
      { title: "BP/RSP", dataIndex: "batAvgRisp" },
    ],
  },
  {
    name: "batters_hits",
    label: "batters.hits",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "H", dataIndex: "batHits" },
      { title: "BB", dataIndex: "batBb" },
      { title: "HBP", dataIndex: "batHbp" },
      { title: "SCB", dataIndex: "batScb" },
      { title: "SF", dataIndex: "batSf" },
    ],
  },
  {
    name: "batters_2b",
    label: "batters.2b",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "2B", dataIndex: "bat2b" },
    ],
  },
  {
    name: "batters_3b",
    label: "batters.3b",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "3B", dataIndex: "bat3b" },
    ],
  },
  {
    name: "batters_sb",
    label: "batters.sb",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "SB", dataIndex: "batSb" },
    ],
  },
  {
    name: "pitchers_w",
    label: "pitchers.w",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "W", dataIndex: "pitWin" },
      { title: "L", dataIndex: "pitLoss" },
      { title: "BAA", dataIndex: "pitBattingAverageAgainst" },
    ],
  },
  {
    name: "pitchers_k",
    label: "pitchers.k",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "K", dataIndex: "pitStrikeOuts" },
      { title: "IP", dataIndex: "pitIp" },
      { title: "K/GI", dataIndex: "pitKPerInnings" },
      { title: "K/BB", dataIndex: "pitStrikeOutsToWalk" },
    ],
  },
  {
    name: "pitchers_sv",
    label: "pitchers.sv",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "SV", dataIndex: "pitSave" },
      { title: "IP", dataIndex: "pitIp" },
      { title: "W", dataIndex: "pitWin" },
      { title: "L", dataIndex: "pitLoss" },
    ],
  },
  {
    name: "defensive",
    label: "defensive",
    columns: [
      { title: "JUGADORES", dataIndex: "name" },
      { title: "FP", dataIndex: "fldFieldingPercentage" },
      { title: "G", dataIndex: "fldGames" },
      { title: "ERR", dataIndex: "fldErrors" },
      { title: "PO", dataIndex: "fldPutOuts" },
      { title: "A", dataIndex: "fldAssists" },
      { title: "AS", dataIndex: "fldStealsAllowed" },
      { title: "CS", dataIndex: "fldCaughtStealing" },
    ],
  },
];

const Leaders = ({ id }: any) => {
  const [active, setActive] = React.useState("pitchers");
  const intl = useIntl();

  categories.map((cat) =>
    cat.columns.forEach((col: any) => (col["key"] = col.dataIndex))
  );
  return (
    <>
      <ul className="nav nav-tabs">
        {categories.map((c) => {
          const { name, label } = c;
          return (
            <li className="nav-item" key={name}>
              <a
                className={`nav-link nav-link-remember ${
                  active === name && "active"
                }`}
                data-toggle="tab"
                href={`#${name}`}
                onClick={() => setActive(name)}
              >
                {intl.formatMessage({ id: label })}
              </a>
            </li>
          );
        })}
      </ul>

      <div className="tab-content">
        {categories.map((c) => {
          const { name } = c;
          return (
            <div
              id={`${name}`}
              key={name}
              className={`tab-pane ${active === name && "active"}`}
            >
              <LeaderDetail {...{ c, id }} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Leaders;
