/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import LogoDarkFooter from "assets/images/logo-dark-footer.png";

export default () => {
  return (
    <footer id="footer" className="border-top">
      <div className="container py-4">
        <div className="row">
          <div className="col text-center-xs my-5">
            {/* logo */}
            <span className="h--70 d-inline-flex align-items-center">
              <img src={LogoDarkFooter} height="70" alt="..." />
            </span>

            <div className="clearfix mb-5">
              {/* social */}
              {/* <a
                href="https://www.facebook.com/baseballeverywhere"
                className="btn btn-sm btn-light transition-hover-top mb-2 rounded-circle"
                rel="noreferrer"
                target="_blank"
                aria-label="facebook page"
              >
                <i className="fi fi-social-facebook"></i>
              </a> */}

              {/* <a
                href="https://www.youtube.com/c/AVG300/featured"
                className="btn btn-sm btn-light transition-hover-top mb-2 rounded-circle"
                rel="noreferrer"
                target="_blank"
                aria-label="youtube page"
              >
                <i className="fi fi-social-youtube"></i>
              </a> */}
            </div>

            <p className="m-0 text-gray-500 fs--14">
              &copy; 2021 AVERAGE 300. All Rights Reserved.
            </p>
          </div>

          <div className="col-12 col-lg-8 my-5">
            <div className="row">
              <div className="col-12 col-lg-4 mb-1">
                {/* mobile only */}
                <button
                  className="clearfix btn btn-toggle btn-sm btn-block text-align-left shadow-md border rounded mb-1 d-block d-lg-none"
                  data-target="#footer_c1"
                  data-toggle-container-className="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
                >
                  <span className="group-icon px-2 py-2 float-start">
                    <i className="fi fi-bars-2"></i>
                    <i className="fi fi-close"></i>
                  </span>

                  <span className="h5 py-2 m-0 float-start">AVERAGE300</span>
                </button>

                {/* desktop only */}
                <h3 className="h5 pt-3 pb-3 m-0 d-none d-lg-block">
                  AVERAGE300
                </h3>

                {/* navigation */}
                <ul
                  id="footer_c1"
                  className="nav flex-column d-none d-lg-block"
                >
                  <li className="list-item">
                    <Link to="/league/league-request" className="d-block py-1">
                      <FormattedMessage id="footer.registerleague" />
                    </Link>
                  </li>

                  <li className="list-item">
                    <Link to="/contact-us" className="d-block py-1">
                      <FormattedMessage id="contactus.contactusbottom" />
                    </Link>
                  </li>

                  {/* <li className="list-item">
                    <Link to="/pricing/pricing-plan" className="d-block py-1">
                      <FormattedMessage id="contactus.buyavg300" />
                    </Link>
                  </li> */}

                  <li className="list-item">
                    <Link to="/video/video-league" className="d-block py-1">
                      Videos
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-lg-4 mb-1">
                {/*  mobile only  */}
                <button
                  className="clearfix btn btn-toggle btn-sm btn-block text-align-left shadow-md border rounded mb-1 d-block d-lg-none"
                  data-target="#footer_c2"
                  data-toggle-container-className="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
                >
                  <span className="group-icon px-2 py-2 float-start">
                    <i className="fi fi-bars-2"></i>
                    <i className="fi fi-close"></i>
                  </span>

                  <span className="h5 py-2 m-0 float-start">
                    AVERAGE300 Score
                  </span>
                </button>

                {/*  desktop only  */}
                <h3 className="h5 pt-3 pb-3 m-0 d-none d-lg-block">
                  AVERAGE300 Score
                </h3>

                {/*  navigation  */}
                <ul
                  id="footer_c2"
                  className="nav flex-column d-none d-lg-block"
                >
                  <li className="list-item">
                    <a
                      className="d-block py-1"
                      href="https://manager.average300.com/#/account/login"
                      target="blank"
                    >
                      Manager
                    </a>
                  </li>

                  <li className="list-item">
                    <a
                      className="d-block py-1"
                      href="https://apps.apple.com/co/app/avg300-score/id1420140535"
                      target="blank"
                    >
                      IOS
                    </a>
                  </li>
                  <li className="list-item">
                    <a
                      className="d-block py-1"
                      href="https://play.google.com/store/apps/details?id=avg300score.app.com.avg300&hl=en&gl=US"
                      target="blank"
                    >
                      Android
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-lg-4 mb-1">
                {/*  mobile only */}
                <button
                  className="clearfix btn btn-toggle btn-sm btn-block text-align-left shadow-md border rounded mb-1 d-block d-lg-none"
                  data-target="#footer_c3"
                  data-toggle-container-className="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
                >
                  <span className="group-icon px-2 py-2 float-start">
                    <i className="fi fi-bars-2"></i>
                    <i className="fi fi-close"></i>
                  </span>

                  <span className="h5 py-2 m-0 float-start">Developer</span>
                </button>

                {/*  desktop only */}
                <h3 className="h5 pt-3 pb-3 m-0 d-none d-lg-block">
                  <FormattedMessage id="footer.developer" />
                </h3>

                {/*  navigation */}
                <ul
                  id="footer_c3"
                  className="nav flex-column d-none d-lg-block"
                >
                  <li className="list-item">
                    <Link
                      to="/documentation/documentacion-api"
                      className="d-block py-1"
                    >
                      API Docs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
