const actions = {
  SET_STATE: "auth/SET_STATE",
  LOGIN: "auth/LOGIN",
  LOAD_CURRENT_ACCOUNT: "auth/LOAD_CURRENT_ACCOUNT",
  LOGOUT: "auth/LOGOUT",
  REGISTER: "auth/REGISTER",
  CONFIRMEMAIL: "auth/CONFIRMEMAIL",
  FORGOTPASSWORD: "auth/FORGOT_PASSWORD",
  RECOVERCONFIRM: "auth/FORGOT_PASSWORD_CONFIRM",

  CONTACT_US: "auth/CONTACT_US",
};

export default actions;
