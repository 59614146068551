/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import actions from "redux/crud/actions";
import "react-modal-video/scss/modal-video.scss";
import Video from "./video";
import ShowGamesVideo from "./showgamevideo";

const model = {
  name: "video",
  fields: [
    { name: "id" },
    { name: "title" },
    { name: "subtitle" },
    { name: "url" },
    { name: "showAsPresentation" },
    { name: "date" },
    { name: "thumbnail" },
    { name: "streaming" },
    { name: "newItem" },
  ],
};

const mapStateToProps = ({ video }: any) => ({
  items: video.items,
  loading: video.loading,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(({ dispatch, items, loading }: PropsFromRedux) => {
  const openVideo = (url: string) => {
    dispatch({
      model: "video",
      type: actions.SELECT,
      payload: { open: true, url },
    });
  };

  const get = () => {
    dispatch({
      model,
      type: actions.LIST,
      method: "listVideos",
    });
  };

  React.useEffect(() => {
    get();
  }, []);

  if (loading !== "succeeded") {
    return <div>...</div>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1">
          <h5 className="h5">
            <FormattedMessage id="videos" />
          </h5>

          <div className="row mt-5">
            {items.map((video: any) => {
              return (
                <div
                  className="col-6 col-md-6 col-lg-3 mb-5 position-relative text-dark clearfix text-decoration-none"
                  onClick={() => openVideo(video.url)}
                  key={Math.random()}
                >
                  <Video {...{ video }} />
                </div>
              );
            })}
            <ShowGamesVideo />
          </div>
        </div>
      </div>
    </div>
  );
});
