export const capitalize = (value: string) => {
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

export const Log = (message: any) => {
  console.log(message);
};

export const SweetAlert = (message: any) => {
  console.log(message);
};
