/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import moment from "moment"
import { Video } from 'types';

export interface VideoProps {
    video: Video
}

export default ({ video }: VideoProps) => {
    const { title, thumbnail, date } = video
    return (
        <>
            <figure className="d-block text-center overlay-dark-hover overlay-opacity-2 rounded overflow-hidden">
                <img
                    className="img-fluid rounded"
                    src={thumbnail}
                    alt={title}
                />
            </figure>
            <p className="mt--20 fs--20 mb--6">
                <span className="h6 text-dark d-block">
                    {title}
                </span>

                <span className="d-block text-muted font-regular fs--14">
                    <time dateTime={date}>{moment(date).format('MMMM Do, h:mm a')}</time>
                </span>
            </p>
        </>
    );
}
