import * as React from "react";
import { withRouter } from "react-router-dom";
import { InfoBox } from "components";

export default withRouter(({ children }: any) => {
  return (
    <>
      <section className="min-vh-70 bg-light">{children}</section>
      <InfoBox />
    </>
  );
});
