/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { useIntl } from "react-intl";
import { LanguageSwitcherProps } from 'interfaces';

const languageModel = [
    { key: 'en-US', name: 'en', flag: 'flag flag-us' } as LanguageSwitcherProps,
    { key: 'es-ES', name: 'es', flag: 'flag flag-es' } as LanguageSwitcherProps,
]

const mapStateToProps = ({ settings }: any) => ({ locale: settings.locale })

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(({ dispatch, locale }: PropsFromRedux) => {
    const intl = useIntl()

    const changeLanguage = ({ key }: LanguageSwitcherProps) => {
        dispatch({
            type: 'settings/CHANGE_SETTING',
            payload: {
                setting: 'locale',
                value: key,
            },
        })
    }

    return (
        <li className="dropdown list-inline-item m-0">
            <div
                className="py-2 d-inline-block"
                data-toggle="dropdown"
            >
                {languageModel.map(e => e.key === locale &&
                    (
                        <>
                            <i className={e.flag}></i>
                            <span className="text-muted pl-2 pr-2">{intl.formatMessage({ id: e.name }).toUpperCase()}</span>
                        </>
                    )
                )}
            </div>

            <div
                aria-labelledby="topDDLanguage"
                className="dropdown-menu dropdown-menu-hover text-uppercase fs--13 px-1 pt-1 pb-0 m-0 max-h-50vh scrollable-vertical"
            >
                {languageModel.map(e => {
                    return (
                        <button
                            onClick={() => changeLanguage(e)}
                            className="dropdown-item text-muted text-truncate line-height-1 rounded p--12 mb-1"
                        >
                            <i className={`${e.flag} mr-3`}></i>
                            {intl.formatMessage({ id: e.name }).toUpperCase()}
                        </button>
                    )
                })}
            </div>
        </li>
    );
})
