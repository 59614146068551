import React from "react";
import ReactDOM from "react-dom";

import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import Amplify, { Auth } from "aws-amplify";

import "./core.css";
import "./core.min.css";
import "./bundle.css";
import "./sow";

//import reportWebVitals from "./reportWebVitals";

import AOS from "aos";
import "bootstrap/dist/js/bootstrap";

import Localization from "./localization";
import Router from "./router";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";

// middlewared
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
  interface JQueryStatic {
    SOW: any;
  }
}

AOS.init();

// You can get the current config object
const awsConfiguration = {
  // Auth: {
  //   // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  //   identityPoolId: process.env.REACT_APP_IDENTITY_POOL,

  //   // REQUIRED - Amazon Cognito Region
  //   region: process.env.REACT_APP_REGION,

  //   // OPTIONAL - Amazon Cognito User Pool ID
  //   userPoolId: process.env.REACT_APP_USER_POOL,

  //   // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  //   userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT,
  // },
  // API: {
  //   graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  //   graphql_headers: async () => ({
  //     Authorization: `${(await Auth.currentSession())
  //       .getAccessToken()
  //       .getJwtToken()}`,
  //   }),
  // },
  aws_appsync_region: process.env.REACT_APP_REGION,
  aws_appsync_authenticationType: "API_KEY", // API_KEY | AWS_IAM | OPENID_CONNECT | AMAZON_COGNITO_USER_POOLS
  aws_appsync_apiKey: process.env.REACT_APP_API_KEY,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BACKEND, // REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_REGION, // OPTIONAL -  Amazon service region
      //
    },
  },
};
Amplify.configure(awsConfiguration);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers(history),
  composeEnhancers(applyMiddleware(...middlewares))
);
sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
export { store, history };
