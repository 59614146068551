import { all, takeEvery, put, call, select } from "redux-saga/effects";
import {
  get,
  list,
  listVideos,
  parentLeague,
  leadersStatistics,
  registerLeague,
  getPositionTable,
} from "services/cruds";
import actions from "./actions";

export function* LIST({ model, method, payload }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: "pending" },
    });

    let callMethod;
    let params;
    switch (method) {
      case "listVideos":
        callMethod = listVideos;
        params = model;
        break;
      case "getPositionTable":
        callMethod = getPositionTable;
        params = { id: payload.id };
        break;
      case "listLeague":
        callMethod = parentLeague;
        params = model;
        break;
      case "leadersStatistics":
        callMethod = leadersStatistics;
        params = payload;
        break;
      default:
        callMethod = list;
        params = model;
        break;
    }

    const items = yield call(callMethod, params);

    yield put({
      name: model.name,
      type: actions.LIST_SUCCESS,
      payload: items,
    });
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.LIST_FAIL,
      payload: error,
    });
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error al obtener los datos.',
    // })
  }
}

export function* GET({ model, id }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loadingDetail: "pending" },
    });

    // let callMethod;
    // let params = { ...model, id };
    // switch (method) {
    //   case "getPositionTable":
    //     callMethod = getPositionTable;
    //     params = { id };
    //     break;
    //   default:
    //     callMethod = get;
    //     break;
    // }

    const item = yield call(get, { ...model, id });

    yield put({
      name: model.name,
      type: actions.GET_SUCCESS,
      payload: item,
    });
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.GET_FAIL,
      payload: error,
    });

    // Sentry.captureException(JSON.stringify(error))
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error al obtener los datos.',
    // })
  }
}

export function* SELECT({ model, payload }) {
  try {
    yield put({
      name: model,
      type: actions.SET_STATE,
      payload,
    });
  } catch (error) {
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error.',
    // })
  }
}

export function* RESET({ model, payload }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: {
        itemsByIds: {},
        items: [],
        item: null,
        fetched: false,
        created: false,
        loading: false,
        loadingDetail: "idle",
        ...payload,
      },
    });
  } catch (error) {
    // Sentry.captureException(JSON.stringify(error))
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error.',
    // })
  }
}

export function* REGISTER_LEAGUE({ name, payload }) {
  try {
    yield put({
      name,
      type: actions.SET_STATE,
      payload: { loading: "pending" },
    });

    const {
      auth: { authorized, email },
    } = yield select();

    if (authorized) {
      payload.email = email;
    }

    const item = yield call(registerLeague, { payload: { ...payload } });

    yield put({
      name,
      type: actions.CREATE_SUCCESS,
      payload: item,
    });
  } catch (error) {
    yield put({
      name,
      type: actions.CREATE_FAIL,
      payload: error,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.RESET, RESET),
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.GET, GET),
    takeEvery(actions.SELECT, SELECT),
    takeEvery(actions.REGISTER_LEAGUE, REGISTER_LEAGUE),
  ]);
}
