/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const mapStateToProps = ({ auth }: any) => ({ auth });

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(({ dispatch, auth }: PropsFromRedux) => {
  const logout = (e: any) => {
    e.preventDefault();
    dispatch({
      type: "auth/LOGOUT",
    });
  };

  return (
    <>
      {auth.authorized ? (
        <li className="list-inline-item mx-1 dropdown">
          <a
            href="#"
            aria-label="Account Options"
            id="dropdownAccountOptions"
            className="btn btn-sm rounded-circle btn-light bg-transparent text-muted shadow-none"
            data-toggle="dropdown"
            aria-expanded="false"
            aria-haspopup="true"
          >
            <span className="group-icon">
              <i className="fi fi-user-male"></i>
              <i className="fi fi-close"></i>
            </span>
          </a>
          <div
            aria-labelledby="dropdownAccountOptions"
            className="prefix-link-icon prefix-icon-dot dropdown-menu dropdown-menu-clean dropdown-menu-invert dropdown-click-ignore p-0 mt--18 fs--15"
          >
            <div className="dropdown-header">
              {auth.name || <FormattedMessage id="anonymous" />}
            </div>
            <div className="dropdown-divider"></div>
            <Link
              to="/"
              // to="/profile/account-settings"
              className="dropdown-item text-truncate font-weight-light"
            >
              <FormattedMessage id="account.settings" />
            </Link>

            <div className="dropdown-divider mb-0"></div>
            <a
              href="#"
              onClick={logout}
              className="prefix-icon-ignore dropdown-footer dropdown-custom-ignore"
            >
              <i className="fi fi-power float-start"></i>
              <FormattedMessage id="logout" />
            </a>
          </div>
        </li>
      ) : (
        <li className="list-inline-item ml--6 mr--6 float-start d-none d-lg-inline-block">
          <Link
            to="/auth/login"
            className="btn btn-sm btn-light bg-transparent text-muted shadow-none m-0"
          >
            <FormattedMessage id="login" />
          </Link>
        </li>
      )}
    </>
  );
});
