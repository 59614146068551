/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./authv1/reducers";
// import menu from "./menu/reducers";
import settings from "./settings/reducers";
import crudReducer from "./crud/reducer";

const models = [
  { name: "video" },
  { name: "registerLeague" },
  { name: "parentLeague" },
  { name: "league" },
  //  { name: "plan" },
  { name: "positionTable" },
];
const categories = [
  { name: "pitchers" },
  { name: "batters" },
  { name: "batters_homeruns" },
  { name: "batters_run_batted_in" },
  { name: "batters_hits" },
  { name: "batters_2b" },
  { name: "batters_3b" },
  { name: "batters_sb" },
  { name: "pitchers_w" },
  { name: "pitchers_k" },
  { name: "pitchers_sv" },
  { name: "defensive" },
];

const wrapperReducer = (reducerFunction, reducerName) => {
  return (state, action) => {
    const { name } = action;
    const isInitializationCall = state === undefined;
    if (name !== reducerName && !isInitializationCall) return state;

    return reducerFunction(state, action);
  };
};

export default (history) => {
  const crudAvg300 = {};
  models.forEach((e) => {
    crudAvg300[e.name] = wrapperReducer(crudReducer, e.name);
  });

  categories.forEach((e) => {
    crudAvg300[e.name] = wrapperReducer(crudReducer, e.name);
  });

  const localReducers = {
    router: connectRouter(history),
    auth,
    // menu,
    settings,
  };

  const reducers = { ...localReducers, ...crudAvg300 };
  return combineReducers(reducers);
};
