import * as React from "react";
import { withRouter } from "react-router-dom";
// import { ConnectWith } from "components";

export default withRouter(({ children }: any) => {
  return (
    <>
      <div className="min-vh-60">{children}</div>
      {/* <ConnectWith /> */}
    </>
  );
});
