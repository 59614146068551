/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { FormattedMessage } from "react-intl";

export default () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="sticky-kit">
            <div>
              <ul
                id="list-example"
                className="p-2 list-unstyled nav-default shadow-md rounded-xl bg-white mb-5"
              >
                <li className="px-3 py-2">
                  <h2 className="h5 font-weight-normal text-primary">
                    <FormattedMessage id="quick.nav" />
                  </h2>
                </li>

                <li>
                  <a
                    className="nav-link rounded-pill scroll-to py-3"
                    href="#spy1"
                  >
                    <i className="fs--13 fi fi-arrow-end-slim"></i>
                    <span>
                      <FormattedMessage id="leaders.of.a.league" />
                    </span>
                  </a>
                </li>

                <li>
                  <a
                    className="nav-link rounded-pill scroll-to py-3"
                    href="#spy2"
                  >
                    <i className="fs--13 fi fi-arrow-end-slim"></i>
                    <FormattedMessage id="statistics.of.a.league" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-8">
          <div className="bg-white p-5 p-4-xs rounded-xl article-format">
            <h2 id="spy1" className="h4 text-primary font-weight-normal">
              <FormattedMessage id="leaders.of.a.league" />
            </h2>

            <h3 className="h5 text-muted">Category Pitchers</h3>

            <h3 className="h6 text-muted">Request</h3>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=pitchers'
            </p>

            <h3 className="h6 text-muted">Response Field</h3>
            <p>
              <code>
                {JSON.stringify({
                  leagueId: "a974fce9-10e6-46d2-88ae-635e1c73cfd9",
                  category: "pitchers",
                  statistics: [
                    {
                      playerId: "da016873-f53a-4a78-93ef-5550b5644c62",
                      name: "Freddy Zeledon",
                      thumbnail: null,
                      team: {
                        id: "b4520d4e-2840-4c67-8ed8-027c16f2ce86",
                        thumbnail:
                          "https://drive.average300.com/media/teams/b4520d4e-2840-4c67-8ed8-027c16f2ce86.jpeg",
                        name: "Estelí",
                      },
                      teamId: "b4520d4e-2840-4c67-8ed8-027c16f2ce86",
                      group: "A",
                      pitEra: 1.23,
                      pitWin: 3,
                      pitLoss: 1,
                      pitWinLoss: "3-1",
                      pitIp: 43.33,
                      pitStrikeOuts: 34,
                      pitWhip: 0.682,
                      order: 0,
                    },
                  ],
                })}
              </code>
            </p>
            <h3 className="h5 text-muted">
              <FormattedMessage id="field.for.all.categories" />
            </h3>

            <p>
              <code>
                "playerId": "da016873-f53a-4a78-93ef-5550b5644c62", "name":
                "Freddy Zeledon", "thumbnail": null, "team":
                {JSON.stringify({
                  id: "b4520d4e-2840-4c67-8ed8-027c16f2ce86",
                  thumbnail:
                    "https://drive.average300.com/media/teams/b4520d4e-2840-4c67-8ed8-027c16f2ce86.jpeg",
                  name: "Estelí",
                })}
                , "teamId": "b4520d4e-2840-4c67-8ed8-027c16f2ce86", "group":
                "A", "order": 0
              </code>
            </p>
            <h3 className="h5 text-muted">Category Winners</h3>

            <h6 className="h6 text-muted">Request</h6>
            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=pitchers_w'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>"pitWin": 7, "pitLoss": 2, "pitBattingAverageAgainst": 0.21,</p>

            <h3 className="h5 text-muted">Category Strike Outs</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=pitchers_k'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>
              "pitStrikeOuts": 58, "pitIp": 40, "pitKPerInnings": 13.05,
              "pitStrikeOutsToWalk": 5.27,
            </p>

            <h3 className="h5 text-muted">Category Batters</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=batters'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>
              "batAvg": 0.487, "batRbi": 7, "batHr": 1, "batObpe": 0.6,
              "batHits": 19, "batSlug": 0.692, "batAb": 39,
            </p>

            <h3 className="h5 text-muted">Category Saves</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=pitchers_sv'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>"pitSave": 7, "pitIp": 23, "pitWin": 2, "pitLoss": 3,</p>

            <h3 className="h5 text-muted">Category Homeruns</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=batters_homeruns'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>"batHr": 13,</p>

            <h3 className="h5 text-muted">Category RBI</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=batters_run_batted_in'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>"batRbi": 39, "batAvgRisp": 0.608,</p>

            <h3 className="h5 text-muted">Category Hits</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=batters_hits'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>
              "batHits": 34, "batBb": 14, "batHbp": 12, "batScb": 1, "batSf": 1,
            </p>

            <h3 className="h5 text-muted">Category Doubles</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=batters_2b'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>"bat2b": 11,</p>

            <h3 className="h5 text-muted">Category Triples</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=batters_3b'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>"bat3b": 11,</p>

            <h3 className="h5 text-muted">Category SB</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=batters_sb'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>"batSb": 9,</p>

            <h3 className="h5 text-muted">Category Defensive</h3>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/leaders?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9&category=defensive'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>
              "fldGames": 23, "fldErrors": 1, "fldPutOuts": 113, "fldAssists":
              7, "fldFieldingPercentage": 1, "fldStealsAllowed": 7,
              "fldCaughtStealing": 4,
            </p>

            <h2 id="spy2" className="h4 text-primary font-weight-normal">
              <FormattedMessage id="statistics.of.a.league" />
            </h2>

            <h6 className="h6 text-muted">Request</h6>

            <p>
              curl --location --request GET
              'https://apiv2.average300.com/statistics?leagueId=a974fce9-10e6-46d2-88ae-635e1c73cfd9'
            </p>

            <h6 className="h6 text-muted">Response Field</h6>

            <p>
              <code>
                {JSON.stringify({
                  leagueId: "a974fce9-10e6-46d2-88ae-635e1c73cfd9",
                  statistics: [
                    {
                      playerId: "0016e6b7-b01b-4ff6-81e4-1dc92f8c4e52",
                      teamId: "27e3762a-fe2e-4722-b128-940a975064fa",
                      team: {
                        id: "27e3762a-fe2e-4722-b128-940a975064fa",
                        name: "Granada",
                        thumbnail:
                          "https://drive.average300.com/media/teams/27e3762a-fe2e-4722-b128-940a975064fa.jpeg",
                      },

                      player: {
                        id: "0016e6b7-b01b-4ff6-81e4-1dc92f8c4e52",
                        firstName: "Aurelio",
                        lastName: "Barillas",
                        thumbnail: null,
                      },
                      batRbi: null,
                      batHr: null,
                      batAb: null,
                      batBb: null,
                      batRuns: null,
                      batHits: null,
                      batAvg: null,
                      pitEr: 3,
                      pitIp: 7,
                      pitEra: 3.86,
                      pitHits: 10,
                      pitWalks: 1,
                      pitStrikeOuts: 2,
                      pitWin: null,
                      pitLoss: null,
                      pitWhip: 1.571,
                      pitStrikeOutsToWalk: 2,
                      pitKPerInnings: 2.57,
                      pitBattingAverageAgainst: 0.4,
                      shootsCount: null,
                      batPa: null,
                      batAbRisp: null,
                      batHitsRisp: null,
                      bat1b: null,
                      bat2b: null,
                      bat3b: null,
                      batSb: null,
                      batSf: null,
                      batHbp: null,
                      batScb: null,
                      batObpe: null,
                      batSlug: null,
                      batAvgRisp: null,
                      pitCi: null,
                      pitScb: null,
                      pitScf: null,
                      pitBf: 26,
                      pitHitBatters: null,
                      fldGames: 1,
                      fldAssists: 2,
                      fldPutOuts: null,
                      fldCaughtStealing: null,
                      fldErrors: 1,
                      fldStealsAllowed: null,
                      fldFieldingPercentage: 0.667,
                    },
                  ],
                })}
              </code>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
