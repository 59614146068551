/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { ErrorMessage } from "@hookform/error-message";
import { Path, useForm, UseFormRegister, SubmitHandler } from "react-hook-form";
import actions from "redux/crud/actions";
import { RegisterLeagueProps } from "interfaces";
import { Button } from "components/elements/index";

type InputProps = {
  name: Path<RegisterLeagueProps>;
  register: UseFormRegister<RegisterLeagueProps>;
  required?: boolean;
  type?: string;
  placeholder: string;
};

type Options = {
  value: string;
  key: string;
};

type SelectProps = {
  name: Path<RegisterLeagueProps>;
  register: UseFormRegister<RegisterLeagueProps>;
  required?: boolean;
  options: Options[];
};

// The following component is an example of your existing Input Component
const Input = ({
  name,
  register,
  required,
  type = "text",
  placeholder,
}: InputProps) => {
  let tmpRules = {};
  if (required) {
    tmpRules = {
      required: "Este campo es requerido",
    };
  }
  return (
    <input
      {...register(name, tmpRules)}
      {...{ type, className: "form-control", placeholder }}
    />
  );
};

const Select = ({ name, register, required, options }: SelectProps) => {
  const intl = useIntl();

  return (
    <select
      {...register(name, { required })}
      {...{ id: name, className: "form-control bs-select" }}
    >
      {options.map((o) => {
        return (
          <option value={o.key}>{intl.formatMessage({ id: o.value })}</option>
        );
      })}
    </select>
  );
};

const mapStateToProps = ({ auth, registerLeague }: any) => ({
  authorized: auth.authorized,
  loading: registerLeague.loading,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const quantities = [
  { key: "LESS20", value: "less20" },
  { key: "MORE20", value: "more20" },
];

const categories = [
  { key: "INFANTILE", value: "infantile" },
  { key: "YOUNG", value: "young" },
  { key: "ADULT", value: "adult" },
  { key: "FEMALE", value: "female" },
  { key: "MANY", value: "many" },
];

const types = [
  { key: "BASEBALL", value: "baseball" },
  { key: "SOFTBALL", value: "softball" },
];

export default connector(
  ({ dispatch, authorized, loading }: PropsFromRedux) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<RegisterLeagueProps>();
    const intl = useIntl();

    const onSubmit: SubmitHandler<RegisterLeagueProps> = (data) => {
      dispatch({
        name: "registerLeague",
        type: actions.REGISTER_LEAGUE,
        payload: data,
      });
    };

    return (
      <div className="container">
        <h2 className="font-weight-light mb-5">
          {intl.formatMessage({ id: "register.league" })}
        </h2>

        <div className="row">
          <div className="col-12 col-lg-8 mb-4">
            <form className="bs-validate" onSubmit={handleSubmit(onSubmit)}>
              {!authorized && (
                <>
                  <div className="form-label-group mb-3">
                    <Input
                      name="email"
                      placeholder={intl.formatMessage({ id: "contact.email" })}
                      register={register}
                      required
                    />
                    <label htmlFor="name">
                      {intl.formatMessage({ id: "contact.email" })}
                    </label>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }: any) => (
                      <div className="alert alert-danger">
                        <div role="alert">{message}</div>
                      </div>
                    )}
                  />
                </>
              )}

              <div className="form-label-group mb-3">
                <Input
                  name="name"
                  placeholder={intl.formatMessage({ id: "name" })}
                  register={register}
                  required
                />
                <label htmlFor="name">
                  {intl.formatMessage({ id: "name" })}
                </label>
              </div>
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }: any) => (
                  <div className="alert alert-danger">
                    <div role="alert">{message}</div>
                  </div>
                )}
              />

              <div className="form-label-group mb-3">
                <Select
                  name="type"
                  register={register}
                  required
                  options={types}
                />
                <label htmlFor="type">
                  {intl.formatMessage({ id: "type" })}
                </label>
              </div>

              <div className="form-label-group mb-3">
                <Select
                  name="category"
                  register={register}
                  required
                  options={categories}
                />
                <label htmlFor="category">
                  {intl.formatMessage({ id: "category" })}
                </label>
              </div>

              <div className="form-label-group mb-3">
                <Select
                  name="quantity"
                  register={register}
                  required
                  options={quantities}
                />
                <label htmlFor="quantity">
                  {intl.formatMessage({ id: "quantity" })}
                </label>
              </div>

              <Button
                {...{
                  label: "register.league",
                  loading: loading === "pending",
                  className: "btn btn-primary btn-block",
                }}
              />
            </form>
          </div>

          <div className="col-12 col-lg-4 mb-4">
            <div className="d-flex">
              <div className="w--40">
                <i className="fi fi-shape-abstract-dots text-gray-500 float-start fs--20"></i>
              </div>

              <div>
                <h2 className="fs--25 font-weight-light">
                  AVERAGE 300 MEDIA GROUP LLC.
                </h2>
                <ul className="list-unstyled m-0 fs--15">
                  <li className="list-item text-muted">
                    Street Address: 9055 NW 121 ST
                  </li>
                  <li className="list-item text-muted">
                    Town / City: HIALEAH GARDENS
                  </li>
                  <li className="list-item text-muted">
                    State / Province: FLORIDA
                  </li>
                </ul>
              </div>
            </div>

            <div className="d-flex mt-4">
              <div className="w--40">
                <i className="fi fi-time text-gray-500 float-start fs--20"></i>
              </div>

              <div>
                <h3 className="h4 font-weight-normal">Working Hours</h3>
                <ul className="list-unstyled m-0 fs--15">
                  <li className="list-item text-muted">
                    Monday &ndash; Friday: 09:00 – 18:00
                  </li>
                  <li className="list-item text-muted">
                    Sunday: 09:00 – 12:00
                  </li>
                </ul>
              </div>
            </div>

            <div className="d-flex mt-4">
              <div className="w--40">
                <i className="fi fi-phone text-gray-500 float-start fs--20"></i>
              </div>

              <div>
                <h3 className="h4 font-weight-normal">Phone Number</h3>
                <ul className="list-unstyled m-0">
                  <li className="list-item mb-2 text-gray-500">
                    {/* <a className="link-muted" href="tel:+1 (786) 736-0021">
                      +1 (786) 736-0021
                    </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
