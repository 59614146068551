import * as React from "react";
import { useIntl } from "react-intl";
import {
  Link,
  Switch,
  Route,
  useParams,
  useRouteMatch,
} from "react-router-dom";

const tabsList = [
  {
    title: "league.detail",
    Component: React.lazy(() => import("./infoleague")),
    path: "",
  },
  {
    title: "position.table",
    Component: React.lazy(() => import("./positiontable")),
    path: "/position-table",
  },
  {
    title: "leader.statistics",
    Component: React.lazy(() => import("./leaders")),
    path: "/leaders",
  },
];

const LeagueDetail = () => {
  const { path, url } = useRouteMatch();
  const { leagueID } = useParams() as any;
  const intl = useIntl();

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 mb--60">
            <nav className="sticky-kit nav-deep nav-deep-light">
              <button
                className="clearfix btn btn-toggle btn-sm btn-block text-align-left shadow-md border rounded mb-1 d-block d-lg-none"
                data-target="#nav_responsive"
                data-toggle-container-className="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
              >
                <span className="group-icon px-2 py-2 float-start">
                  <i className="fi fi-bars-2"></i>
                  <i className="fi fi-close"></i>
                </span>

                <span className="h5 py-2 m-0 float-start">Account Menu</span>
              </button>

              <ul
                id="nav_responsive"
                className="nav flex-column d-none d-lg-block"
              >
                {tabsList.map((t) => (
                  <li className="nav-item">
                    <Link
                      to={`${url}${t.path}`}
                      key={t.path}
                      className="nav-link px-0"
                    >
                      <i className="fi fi-arrow-end m-0 fs--12"></i>
                      <span className="px-2 d-inline-block">
                        {intl.formatMessage({ id: t.title })}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-9">
            <Switch>
              {tabsList.map((e: any) => (
                <Route path={`${path}${e.path}`} exact={true} key={e.path}>
                  <React.Suspense fallback={null}>
                    <e.Component id={leagueID} />
                  </React.Suspense>
                </Route>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeagueDetail;
