import { all, takeEvery, put, call } from "redux-saga/effects";
import { Log } from "utils";
// import { notification } from 'antd'
import { history } from "index";
import {
  avg300Login,
  avg300CurrentAccount,
  avg300Logout,
  avg300Register,
  avg300ConfirmEmail,
  avg300ForgotPassword,
  avg300RecoverConfirm,
  contactUs,
} from "services/avg300.auth";
import actions from "./actions";

export function* LOGIN({ payload }) {
  try {
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: true,
      },
    });
    const success = yield call(avg300Login, payload);
    if (success) {
      yield put({
        type: "auth/LOAD_CURRENT_ACCOUNT",
      });
      yield history.push("/");
      // notification.success({
      //   message: 'Ingresado',
      //   description: 'Autenticación satistfactoria.',
      // })
    } else {
      yield put({
        type: "auth/SET_STATE",
        payload: {
          loading: false,
        },
      });
    }
  } catch (error) {
    Log(error);

    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: false,
      },
    });
    // Sentry.captureException(JSON.stringify(error))
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error al autenticar.',
    // })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  try {
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: true,
      },
    });
    const response = yield call(avg300CurrentAccount);
    if (response) {
      const {
        id,
        email,
        first_name: name,
        last_name: family_name,
        avatar_url: avatar,
        groups,
      } = response;
      yield put({
        type: "auth/SET_STATE",
        payload: {
          id,
          name,
          family_name,
          avatar,
          email,
          groups,
          phone_number: "",
          authorized: true,
        },
      });
    }
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: false,
      },
    });
  } catch (error) {
    Log(error);

    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: false,
      },
    });
    // Sentry.captureException(JSON.stringify(error))
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error al cargar la cuenta.',
    // })
  }
}

export function* LOGOUT() {
  try {
    yield call(avg300Logout);
    yield put({
      type: "auth/SET_STATE",
      payload: {
        id: "",
        name: "",
        family_name: "",
        avatar: "",
        role: "",
        email: "",
        phone_number: "",
        authorized: false,
        loading: false,
        groups: [],
      },
    });
  } catch (error) {
    Log(error);

    // Sentry.captureException(JSON.stringify(error))
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error.',
    // })
  }
}

export function* REGISTER({ payload }) {
  try {
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: true,
      },
    });
    const success = yield call(avg300Register, payload);
    if (success) {
      yield history.push("/auth/login");
      // notification.success({
      //   message: 'Registrado',
      //   description: success.detail,
      // })
    }
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: false,
      },
    });
  } catch (error) {
    // Sentry.captureException(JSON.stringify(error))
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error al registrar.',
    // })
  }
}

export function* CONFIRMEMAIL({ payload }) {
  try {
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: true,
      },
    });
    const success = yield call(avg300ConfirmEmail, payload);
    if (success) {
      yield history.push("/auth/login");
      // notification.success({
      //   message: 'Registrado',
      //   description: success.detail,
      // })
    }
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: false,
      },
    });
  } catch (error) {
    // Sentry.captureException(JSON.stringify(error))
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error.',
    // })
  }
}

export function* FORGOTPASSWORD({ payload }) {
  try {
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: true,
      },
    });
    const success = yield call(avg300ForgotPassword, payload);
    if (success) {
      yield history.push("/auth/login");
      // notification.success({
      //   description: success.detail,
      // })
    }
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: false,
      },
    });
  } catch (error) {
    // Sentry.captureException(JSON.stringify(error))
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error.',
    // })
  }
}

export function* RECOVERCONFIRM({ payload }) {
  try {
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: true,
      },
    });
    const success = yield call(avg300RecoverConfirm, payload);
    if (success) {
      yield history.push("/auth/login");
      // notification.success({
      //   description: success.detail,
      // })
    }
    yield put({
      type: "auth/SET_STATE",
      payload: {
        loading: false,
      },
    });
  } catch (error) {
    // Sentry.captureException(JSON.stringify(error))
    // notification.warning({
    //   message: 'Error',
    //   description: 'Ha ocurrido un error.',
    // })
  }
}

export function* CONTACT_US({ payload }) {
  try {
    yield put({
      type: "auth/SET_STATE",
      payload: { loading: true },
    });

    yield call(contactUs, { payload });

    yield put({
      type: "auth/SET_STATE",
      payload: { loading: false },
    });
    yield history.push("/");
  } catch (error) {
    yield put({
      type: "auth/SET_STATE",
      payload: { loading: false },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.CONFIRMEMAIL, CONFIRMEMAIL),
    takeEvery(actions.FORGOTPASSWORD, FORGOTPASSWORD),
    takeEvery(actions.RECOVERCONFIRM, RECOVERCONFIRM),
    takeEvery(actions.CONTACT_US, CONTACT_US),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
