import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import actions from "redux/crud/actions";
import moment from "moment";
import { Link } from "react-router-dom";

const model = {
  name: "parentLeague",
  fields: [
    { name: "id" },
    { name: "name" },
    { name: "thumbnail" },
    { name: "date" },
    { name: "status" },
  ],
};

const categories: { [key: string]: string } = {
  REGULAR_TOURNAMENT: "regular.tournament",
  PLAY_OFF: "play.off",
  FINAL: "final",
  REGULAR_TOURNAMENT_FINAL: "eliminatories",
  SEMIFINALS: "semifinals",
};

const mapStateToProps = ({ parentLeague }: any) => ({
  items: parentLeague.items,
  loading: parentLeague.loading,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(({ dispatch, items, loading }: PropsFromRedux) => {
  const get = () => {
    dispatch({
      model,
      type: actions.LIST,
      method: "listLeague",
    });
  };

  React.useEffect(() => {
    get();
  }, []);

  if (loading !== "succeeded") {
    return <div>...</div>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1">
          <h5 className="h5">
            <FormattedMessage id="leagues" />
          </h5>

          <div className="row mt-5">
            {items.map((league: any) => {
              const {
                thumbnail,
                name,
                date,
                tournaments: { items: tournaments },
              } = league;
              return (
                <div
                  className="col-6 col-md-6 col-lg-3 mb-5 position-relative text-dark clearfix text-decoration-none"
                  key={Math.random()}
                >
                  <figure className="d-block text-center overlay-dark-hover overlay-opacity-2 rounded overflow-hidden">
                    <img
                      className="img-fluid rounded"
                      src={thumbnail}
                      alt={name}
                    />
                  </figure>
                  <p className="mt--20 fs--20 mb--6">
                    <span className="h6 text-dark d-block">{name}</span>

                    <span className="d-block text-muted font-regular fs--14">
                      <time dateTime={date}>
                        {moment(date).format("MMMM Do")}
                      </time>
                    </span>
                    {tournaments.map((t: any) => (
                      <Link to={`/leagues/${t.id}`} key={t.id}>
                        <span className="d-block">
                          <FormattedMessage id={categories[t.category]} />
                        </span>
                      </Link>
                    ))}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});
