import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import actions from "redux/crud/actions";
import { Table } from "./elements";

const mapStateToProps = (state: any, ownProps: any) => ({
  items: state[ownProps.c.name].items,
  loading: state[ownProps.c.name].loading,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type leagueDetailProps = PropsFromRedux & { id: string; c: any };

export default connector(
  ({ dispatch, items, loading, c, id }: leagueDetailProps) => {
    const { name } = c;
    React.useEffect(() => {
      dispatch({
        model: { name },
        type: actions.LIST,
        method: "leadersStatistics",
        payload: { id, name },
      });
    }, [id]);

    if (loading !== "succeeded") {
      return <>...</>;
    }

    if (items.length === 0) {
      return <FormattedMessage id="empty.statistics.table" />;
    }

    return <Table {...{ columns: c.columns, dataSource: items }} />;
  }
);
