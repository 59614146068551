/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default () => (
  <section className="py-5 bg-light">
    <div className="container z-index-1">
      <div className="row text-center-xs">
        <div className="col-12 col-md-8">
          <h3 className="m-0">
            <FormattedMessage id="contactus.question" />
          </h3>
          <p className="m-0">
            <FormattedMessage id="contactus.answer" />
          </p>
        </div>

        <div className="col-12 mt-4 d-block d-md-none">
          {/*  mobile spacer */}
        </div>

        <div className="col-12 col-md-4 text-align-end">
          <Link
            to="/contact-us"
            className="btn btn-primary btn-soft d-block-xs"
          >
            <FormattedMessage id="contactus.contactusbottom" />
          </Link>
        </div>
      </div>
    </div>
  </section>
);
