/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import LogoDark from "assets/images/logo-dark.png";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LanguageSwitcher from "./languageswitcher";
import User from "./user";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = ({ auth }: any) => ({ authorized: auth.authorized });

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(({ authorized }: PropsFromRedux) => {
  React.useEffect(() => {
    require("./scroll");
  }, []);

  return (
    <header id="header" className="shadow-xs">
      {/* TOP BAR */}
      <div id="top_bar" className="bg-light fs--14">
        {/* optional if body.header-sticky is present: add .js-ignore class to ignore autohide and stay visible all the time */}
        <div className="container">
          <div className="text-nowrap">
            {/* change with .scrollable-horizontal to horizontally scroll, if -only- no dropdown is present */}
            <div className="d-flex justify-content-between">
              <div className="d-inline-block float-start">
                <ul className="list-inline m-0">
                  {/* LANGUAGE */}
                  <LanguageSwitcher />
                  {/* LANGUAGE */}

                  {/* CURRENCY */}
                  {/*<li className="dropdown list-inline-item m-0">
                    <span className="text-muted">/</span>
                   

                    <a
                      id="topDDCurrency"
                      href="#"
                      className="py-2 d-inline-block"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="text-muted pl-2 pr-2">USD</span>
                    </a>

                    <div
                      aria-labelledby="topDDCurrency"
                      className="dropdown-menu dropdown-menu-hover text-uppercase text-center fs--13 px-1 pt-1 pb-0 m-0 max-h-50vh w-auto scrollable-vertical"
                    >
                      <a
                        href="#!"
                        className="active dropdown-item text-muted text-truncate line-height-1 rounded pt--12 pb--12 mb-1"
                      >
                        USD
                      </a>
                      <a
                        href="#!"
                        className="dropdown-item text-muted text-truncate line-height-1 rounded pt--12 pb--12 mb-1"
                      >
                        EUR
                      </a>
                      <a
                        href="#!"
                        className="dropdown-item text-muted text-truncate line-height-1 rounded pt--12 pb--12 mb-1"
                      >
                        GBP
                      </a>
                    </div>
                  </li>
                  {/* /CURRENCY */}
                </ul>
              </div>

              <div className="d-inline-block float-end">
                <ul className="list-inline m-0">
                  <li className="dropdown list-inline-item">
                    {/* <a
                      href="tel:+1 (786) 736-0021"
                      className="p-2 d-inline-block font-weight-medium"
                    >
                      <i className="float-start fi fi-phone"></i> +1 (786)
                      736-0021
                    </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /TOP BAR */}

      {/* NAVBAR */}
      <div className="container position-relative">
        {/* 

						[SOW] SEARCH SUGGEST PLUGIN
						++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++
						PLEASE READ DOCUMENTATION
						documentation/plugins-sow-search-suggest.html
						++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++ ++

					*/}
        <form
          action="search-result-2.html"
          method="GET"
          data-autosuggest="on"
          data-mode="json"
          data-json-max-results="10"
          data-json-related-title="Explore Smarty"
          data-json-related-item-icon="fi fi-star-empty"
          data-json-suggest-title="Suggestions for you"
          data-json-suggest-noresult="No results for"
          data-json-suggest-item-icon="fi fi-search"
          data-json-suggest-min-score="5"
          data-json-highlight-term="true"
          data-contentType="application/json; charset=utf-8"
          data-dataType="json"
          data-container="#sow-search-container"
          data-input-min-length="2"
          data-input-delay="250"
          data-related-keywords=""
          data-related-url="_ajax/search_suggest_related.json"
          data-suggest-url="_ajax/search_suggest_input.json"
          data-related-action="related_get"
          data-suggest-action="suggest_get"
          className="js-ajax-search sow-search sow-search-over hide d-inline-flex"
        >
          <div className="sow-search-input w-100">
            <div className="input-group-over d-flex align-items-center w-100 h-100 rounded shadow-md">
              <input
                placeholder="what are you looking today?"
                aria-label="what are you looking today?"
                name="s"
                type="text"
                className="form-control-sow-search form-control form-control-lg shadow-xs"
                value=""
                autoComplete="off"
              />

              <span className="sow-search-buttons">
                {/* search button */}
                <button
                  aria-label="Global Search"
                  type="submit"
                  className="btn bg-transparent shadow-none m-0 px-2 py-1 text-muted"
                >
                  <i className="fi fi-search fs--20"></i>
                </button>

                {/* close : mobile only (d-inline-block d-lg-none) */}
                <a
                  href="javascript:;"
                  className="btn-sow-search-toggler btn btn-light shadow-none m-0 px-2 py-1 d-inline-block d-lg-none"
                >
                  <i className="fi fi-close fs--20"></i>
                </a>
              </span>
            </div>
          </div>

          {/* search suggestion container */}
          <div
            className="sow-search-container w-100 p-0 hide shadow-md"
            id="sow-search-container"
          >
            <div className="sow-search-container-wrapper">
              {/* main search container */}
              <div className="sow-search-loader p-3 text-center hide">
                <i className="fi fi-circle-spin fi-spin text-muted fs--30"></i>
              </div>

              {/* 
									AJAX CONTENT CONTAINER 
									SHOULD ALWAYS BE AS IT IS : NO COMMENTS OR EVEN SPACES!
								*/}
              <div className="sow-search-content rounded w-100 scrollable-vertical"></div>
            </div>
          </div>
          {/* /search suggestion container */}

          {/* 

							overlay combinations:
								overlay-dark opacity-* [1-9]
								overlay-light opacity-* [1-9]

						*/}
          <div className="sow-search-backdrop overlay-dark opacity-3 hide"></div>
        </form>
        {/* /SEARCH */}

        <nav className="navbar navbar-expand-lg navbar-light justify-content-lg-between justify-content-md-inherit">
          <div className="align-items-start">
            {/* mobile menu button : show */}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarMainNav"
              aria-controls="navbarMainNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg width="25" viewBox="0 0 20 20">
                <path d="M 19.9876 1.998 L -0.0108 1.998 L -0.0108 -0.0019 L 19.9876 -0.0019 L 19.9876 1.998 Z"></path>
                <path d="M 19.9876 7.9979 L -0.0108 7.9979 L -0.0108 5.9979 L 19.9876 5.9979 L 19.9876 7.9979 Z"></path>
                <path d="M 19.9876 13.9977 L -0.0108 13.9977 L -0.0108 11.9978 L 19.9876 11.9978 L 19.9876 13.9977 Z"></path>
                <path d="M 19.9876 19.9976 L -0.0108 19.9976 L -0.0108 17.9976 L 19.9876 17.9976 L 19.9876 19.9976 Z"></path>
              </svg>
            </button>

            {/* 
								Logo : height: 70px max
							*/}
            <Link to="/" className="navbar-brand">
              <img src={LogoDark} height="40" alt="..." />
            </Link>
          </div>

          {/* Menu */}
          {/*

							Dropdown Classes (should be added to primary .dropdown-menu only, dropdown childs are also affected)
								.dropdown-menu-dark 		- dark dropdown (desktop only, will be white on mobile)
								.dropdown-menu-hover 		- open on hover
								.dropdown-menu-clean 		- no background color on hover
								.dropdown-menu-invert 		- open dropdown in oposite direction (left|right, according to RTL|LTR)
								.dropdown-menu-uppercase 	- uppercase text (font-size is scalled down to 13px)
								.dropdown-click-ignore 		- keep dropdown open on inside click (useful on forms inside dropdown)

								Repositioning long dropdown childs (Example: Pages->Account)
									.dropdown-menu-up-n100 		- open up with top: -100px
									.dropdown-menu-up-n100 		- open up with top: -150px
									.dropdown-menu-up-n180 		- open up with top: -180px
									.dropdown-menu-up-n220 		- open up with top: -220px
									.dropdown-menu-up-n250 		- open up with top: -250px
									.dropdown-menu-up 			- open up without negative class


								Dropdown prefix icon (optional, if enabled in variables.scss)
									.prefix-link-icon .prefix-icon-dot 		- link prefix
									.prefix-link-icon .prefix-icon-line 	- link prefix
									.prefix-link-icon .prefix-icon-ico 		- link prefix
									.prefix-link-icon .prefix-icon-arrow 	- link prefix

								.nav-link.nav-link-caret-hide 	- no dropdown icon indicator on main link
								.nav-item.dropdown-mega 		- required ONLY on fullwidth mega menu

								Mobile animation - add to .navbar-collapse:
								.navbar-animate-fadein
								.navbar-animate-fadeinup
								.navbar-animate-bouncein

						*/}
          <div
            className="collapse navbar-collapse navbar-animate-fadein"
            id="navbarMainNav"
          >
            {/* MOBILE MENU NAVBAR */}
            <div className="navbar-xs d-none">
              {/* .sticky-top */}

              {/* mobile menu button : close */}
              <button
                className="navbar-toggler pt-0"
                type="button"
                data-toggle="collapse"
                data-target="#navbarMainNav"
                aria-controls="navbarMainNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <svg width="20" viewBox="0 0 20 20">
                  <path d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z"></path>
                </svg>
              </button>

              {/* 
									Mobile Menu Logo 
									Logo : height: 70px max
								*/}
              <a className="navbar-brand" href="index.html">
                <img src={LogoDark} height="40" alt="..." />
              </a>
            </div>
            {/* /MOBILE MENU NAVBAR */}

            {/* NAVIGATION */}
            <ul className="navbar-nav">
              {/* Menu */}
              {/*

									Dropdown Classes (should be added to primary .dropdown-menu only, dropdown childs are also affected)
										.dropdown-menu-dark 		- dark dropdown (desktop only, will be white on mobile)
										.dropdown-menu-hover 		- open on hover
										.dropdown-menu-clean 		- no background color on hover
										.dropdown-menu-invert 		- open dropdown in oposite direction (left|right, according to RTL|LTR)
										.dropdown-menu-uppercase 	- uppercase text (font-size is scalled down to 13px)
										.dropdown-click-ignore 		- keep dropdown open on inside click (useful on forms inside dropdown)

										Repositioning long dropdown childs (Example: Pages->Account)
											.dropdown-menu-up-n100 		- open up with top: -100px
											.dropdown-menu-up-n100 		- open up with top: -150px
											.dropdown-menu-up-n180 		- open up with top: -180px
											.dropdown-menu-up-n220 		- open up with top: -220px
											.dropdown-menu-up-n250 		- open up with top: -250px
											.dropdown-menu-up 			- open up without negative class


										Dropdown prefix icon (optional, if enabled in variables.scss)
											.prefix-link-icon .prefix-icon-dot 		- link prefix
											.prefix-link-icon .prefix-icon-line 	- link prefix
											.prefix-link-icon .prefix-icon-ico 		- link prefix
											.prefix-link-icon .prefix-icon-arrow 	- link prefix

										.nav-link.nav-link-caret-hide 	- no dropdown icon indicator on main link
										.nav-item.dropdown-mega 		- required ONLY on fullwidth mega menu

								*/}
              {/* mobile only image + simple search (d-block d-sm-none) */}
              <li className="nav-item d-block d-sm-none">
                {/* image */}
                <div className="mb-4">
                  <img
                    width="600"
                    height="600"
                    className="img-fluid"
                    src="demo.files/svg/artworks/people_crossbrowser.svg"
                    alt="..."
                  />
                </div>

                {/* search */}
                <form
                  method="get"
                  action="#!search"
                  className="input-group-over mb-5 bg-light p-2 form-control-pill"
                >
                  <input
                    type="text"
                    name="keyword"
                    value=""
                    placeholder="Quick search..."
                    className="form-control border-dashed"
                  />
                  <button className="btn btn-primary fi fi-search p-0 ml-2 mr-2 w--50 h--50"></button>
                </form>
              </li>

              {/* home */}
              <Link to="/" className="nav-link">
                <span>
                  <FormattedMessage id="home" />
                </span>
              </Link>
              {/* videos */}
              <Link to="/video/video-league" className="nav-link">
                <span>
                  <FormattedMessage id="videos" />
                </span>
              </Link>
              {/* Buy league */}
              {/* <Link to="/pricing/pricing-plan" className="nav-link">
                <span>
                  <FormattedMessage id="buy.league" />
                </span>
              </Link>*/}
              <Link to="/leagues" className="nav-link">
                <span>
                  <FormattedMessage id="leagues" />
                </span>
              </Link>
              {/* documentation */}
              <li className="nav-item">
                <Link
                  to="/documentation/documentacion-api"
                  className="nav-link"
                >
                  <span>
                    <FormattedMessage id="documentation" />
                  </span>
                </Link>
              </li>

              {/* social : mobile only (d-block d-sm-none)*/}
              <li className="nav-item d-block d-sm-none text-center mb-4">
                <h3 className="h6 text-muted">Follow Us</h3>

                {/* <a
                  href="https://www.facebook.com/baseballeverywhere"
                  target="blank"
                  className="btn btn-sm btn-facebook transition-hover-top mb-2 rounded-circle text-white"
                  rel="noopener"
                >
                  <i className="fi fi-social-facebook"></i>
                </a> */}

                {/* <a
                  href="https://www.youtube.com/c/AVG300/featured"
                  target="blank"
                  className="btn btn-sm btn-youtube transition-hover-top mb-2 rounded-circle text-white"
                  rel="noopener"
                >
                  <i className="fi fi-social-youtube"></i>
                </a> */}
              </li>

              {/* Get Smarty : mobile only (d-block d-sm-none)*/}
              <li className="nav-item d-block d-sm-none">
                {authorized ? (
                  <Link
                    to="/profile/account-settings"
                    className="btn btn-block btn-primary shadow-none text-white m-0"
                  >
                    <FormattedMessage id="account.settings" />
                  </Link>
                ) : (
                  <Link
                    to="/auth/login"
                    className="btn btn-block btn-primary shadow-none text-white m-0"
                  >
                    <FormattedMessage id="login" />
                  </Link>
                )}
              </li>
            </ul>
            {/* /NAVIGATION */}
          </div>

          {/* OPTIONS */}
          <ul className="list-inline list-unstyled mb-0 d-flex align-items-end">
            {/* <li className="list-inline-item mx-1 dropdown">
              <a
                href="#"
                aria-label="website search"
                className="btn-sow-search-toggler btn btn-sm rounded-circle btn-light bg-transparent text-muted shadow-none"
              >
                <i className="fi fi-search"></i>
              </a>
            </li> */}
            <User />
          </ul>
          {/* /OPTIONS */}
        </nav>
      </div>
      {/* /NAVBAR */}
    </header>
  );
});
